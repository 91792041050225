@font-face {
  font-family: 'Fredoka';
  src: url('../../fonts/Fredoka/Fredoka-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fredoka';
  src: url('../../fonts/Fredoka/Fredoka-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fredoka';
  src: url('../../fonts/Fredoka/Fredoka-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fredoka';
  src: url('../../fonts/Fredoka/Fredoka-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fredoka';
  src: url('../../fonts/Fredoka/Fredoka-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Jcfonts linotte';
  src: url('../../fonts/Linotte/Linotte-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Jcfonts linotte';
  src: url('../../fonts/Linotte/Linotte-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Jcfonts linotte';
  src: url('../../fonts/Linotte/Linotte-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Jcfonts linotte';
  src: url('../../fonts/Linotte/Linotte-Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Jcfonts linotte';
  src: url('../../fonts/Linotte/Linotte-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}
@font-face {
	font-family: 'Custom';
	src: url('../../fonts/Custom/Svgconvert.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
  font-display: swap;
}
