
          @import "@assets/scss/config/_index.scss";
// @import '@styles/scss/components/list-table';
@import '../../../../../assets/scss/components/list-table';
.billing__buttons > span {
  width: 35px !important;
}
.user__left {
  width: 35%;
}
@include media-breakpoint-down(sm) {
  .user__left {
    width: 100%;
    max-width: 100%;
  }
}
