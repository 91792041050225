.menu__title {
  display: flex;
  padding: 12px;
  flex-direction: row;
  flex: 0 0 auto;
  border-bottom: 1px solid $border-color;
  color: $neutral-700;
}

.linked-org {
  background: $secondary-1;
}

.linked__item-img-wrap {
  border: 1px solid $border-color;
  display: flex;
  width: 60px;
  height: 52px;
  padding: 9px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  border-radius: 3px;
}

.linked__item-label {
  color: $neutral-700;
  text-align: start;
  line-height: $line-height-base;
}

.btn.linked__item-link {
  border: none;
  &:hover {
    .linked__item-label {
      text-decoration: underline;
    }
  }
}

.btn.linked__item-unlink {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.team__role {
  color: #6f6f90ad;
}

.activate {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
}

.activate__icon {
  position: relative;
  display: flex;
  height: auto;
  padding-right: 12px;
  justify-content: center;
  align-items: center;
  flex: 0 auto;
  color: #b4afb9;
  font-size: 24px;
}

@include media-breakpoint-down(sm) {
  .activate__button {
    width: 100%;
  }
}
.activate__code {
  display: flex;
  gap: 1;
  width: 100%;
  input {
    width: 64px;
    height: 64px;
    padding: 0;
    font-size: 36px !important;
  }
}
.activate__input {
  outline: none;
  font-weight: 700;
  color: $neutral-700 !important;
  line-height: normal;
  background-color: $secondary-3;
  border-radius: 4px;
  border: 1px solid $border-color;
  // border-bottom: 1px solid $border-color;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 8px;
  margin-left: 0;
  text-align: center;
  text-transform: uppercase;
  box-shadow: none;
  // transition: all 0.2s ease-out;
}
.activate__input--apply {
  width: 56px;
  height: 56px;
  font-size: 42px;
}
