.c__badge {
  background-color: #6394f8;
  border-radius: 10px;
  color: #fdfdfd;
  position: absolute;
  left: 9px;
  top: -12px;
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  margin-left: 6px;
  padding: 3px 6px;
  letter-spacing: -0.09rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  .c__badge__count {
    display: flex;
    letter-spacing: 0.01rem;
    align-items: center;
    justify-content: center;
    min-width: 6px;
    font-weight: 700;
    padding-top: 2px;
  }
}

.item__img-badge {
  width: 21px;
  height: 21px;
  color: $neutral-100;
  background-color: #0e75f9;
  border-radius: 50% !important;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  margin-right: -5px;
  padding-top: 3px;
  font-size: 11px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}
