.dropdown-menu {
  background-color: $secondary-3;
  color: $neutral-700;
}
.dropdown-top-arrow {
  &.dropdown-menu {
    border-radius: 4px;
    padding: 0;
    @include media-breakpoint-up(sm) {
      top: 40px !important;
    }
    @include media-breakpoint-down(sm) {
      // top: 40px !important;
      border-radius: 19px 19px 0px 0px;
    }
    width: 420px;
  }

  .dropdown__inner {
    background: $secondary-1;
    border-radius: 4px;
    z-index: 1;
    @include media-breakpoint-down(sm) {
      border-radius: 12px;
    }
  }

  &:after {
    background: $neutral-300;
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    top: -6px;
    right: 10px;
    transform: rotate(45deg) scale(1);
    z-index: 0;
  }
}

.dropdown-toggle.no-caret {
  &:after {
    display: none;
  }
}

.cart,
.activity {
  &__dropdown-header {
    display: flex;
    height: 75px;
    padding-right: 16px;
    padding-left: 16px;
    align-items: center;
    flex: 0 0 auto;
    color: $neutral-700;
    border-bottom: 1px solid var(--border-input);
  }

  &__dropdown-footer {
    display: flex;
    height: 30px;
    padding-right: 1rem;
    padding-left: 1rem;
    align-items: center;
    flex: 0 0 auto;
    border-top: 1px solid var(--border-input);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $secondary-3;
    font-size: 10px;
  }
}

.dropdown-menu {
  @include box-shadow($dropdown-box-shadow);
  overflow: auto;
}

.filter__dropdown,
.grab__dropdown {
  &.dropdown-menu {
    max-height: initial;
  }
}

.dropdown-toggle.no-caret {
  &:after {
    display: none;
  }
}
.selected__overlay {
  display: none;
}

@include media-breakpoint-down(sm) {
  .mobile__dropdown {
    flex-direction: column;
    --x: 0;
    --y: 0;
    --z: 0;
    background: #fff;
    padding: 12px 0;
    //top: auto !important;
    border-radius: 12px 12px 0 0;
    display: flex !important;
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    width: 100% !important;
    left: 0 !important;
    transform: translate3d(var(--x), var(--y), var(--z)) !important;
    transition: 0.35s cubic-bezier(0.33, 1, 0.53, 1) !important;
    overflow: scroll;
    z-index: 100;

    &:not(.show) {
      --y: 150%;
      transition: 0.5s cubic-bezier(0.33, 1, 0.53, 1) !important;
    }
  }

  .selected__overlay:not(.hidden) {
    display: flex;
    visibility: visible;
    background: rgb(0 0 0 / 28%);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.2s cubic-bezier(0.33, 1, 0.53, 1);
    opacity: 1;
    z-index: 100;
  }
}
