
          @import "@assets/scss/config/_index.scss";
.suggested__list {
  overflow: auto;
  height: 100%;
  list-style: unset;
}
.suggested__list::-webkit-scrollbar {
  height: 0px;
}
.suggested__list-wrap {
  // background: $secondary-5;
  // border-bottom: 1px solid $border-color;
  list-style: none;
}
