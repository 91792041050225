
          @import "@assets/scss/config/_index.scss";
.checkout__section {
  .note--user {
    border-radius: 56px;
    padding: 12px 24px;
    max-width: 480px;
  }
}
.checkout__block {
  input {
    padding: 21px
  }
}
.checkout {
  &__page {
    .logo__wrap {
      @include media-breakpoint-down(sm) {
        border-bottom: 1px solid var(--border-input);
        min-height: 80px;
      }
    }

    .btn__remove {
      min-height: 6px !important;
      color: var(--text-subtext);
      &:hover {
        color: var(--text-danger);
      }
    }

    .qty__input {
      border: unset;
      background-color: var(--bkg-color);
      color: var(--text-subtext);
      flex: 0;
      font-size: 16px;
      font-weight: $font-weight-bold;
      text-align: center;
      min-height: 42px;
      border-radius: 3px;
      width: 49px;
    }
    .qty__input:focus-visible {
      outline: none;
    }

    .checkout__section {
      @include media-breakpoint-up(lg) {
        border-right: 1px solid var(--border-input);

        width: 52%;
        padding-right: 6%;
      }
    }

    .summary__section {
      background-color: $secondary-3;
      padding-top: 5%;
      padding-left: 4%;
      @include media-breakpoint-up(lg) {
        width: 40%;
      }

      position: relative;
      &:before {
        content: '';
        background-color: $secondary-3;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 300%;
        z-index: 0;
        border-bottom: 1px solid $border-color;
      }
    }

    .summary__content {
      position: relative !important;
      z-index: 1;
    }

    .item__img-badge {
      background-color: $secondary;
    }
  }
}

.group__label {
  color: $neutral-600;
  padding: 2px 12px;
  position: absolute !important;
  left: 50%;
  top: 0;
  background-color: $neutral-100;
  transform: translate(-50%, -65%);
  z-index: 1;
}

.btn.google {
  background-color: #000;
  border-color: #000;
}

.btn.paypal {
  background-color: #ffc439;
  border-color: #ffc439;
}

.crypto__icon {
  padding: 6px;
  width: 46px;
}

.btn__crypto {
  cursor: pointer;

  .form-check-input {
    font-size: $font-size-base;
  }
}

.ex__checkout {
  @include media-breakpoint-down(md) {
    .btn__checkout {
      width: 100%;
    }
  }
}

.crypto__section {
  @include media-breakpoint-down(md) {
    .btn__crypto {
      width: 100%;
    }

    .form-check-input {
      font-size: 20px;
    }
  }
}

.checkout__block {
  &-hd {
    padding: 18px 16px !important;
  }

  .checkout__svg {
    margin-right: 12px !important;
    width: 40px;
  }

  &-bd {
    border-radius: 0 0 6px 6px;
    padding: 20px 16px !important;
    background: $secondary-5;
  }

  .form-control::placeholder {
    color: $neutral-600;
  }

  .form-check-input {
    font-size: $font-size-base;
  }
}

.order-summary {
  cursor: pointer;
}

.mobile__summary {
  height: 0;
  overflow: hidden;
  transition: height 0.35s;
}
