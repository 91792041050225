
          @import "@assets/scss/config/_index.scss";
html {
  --color-primary: #f1f1f1;
  --color-primary-light: #152640;
  --color-secondary: #eec413;
  --text-color: #222;
  --bkg-color: #FBFCFE;
}
html[data-theme="dark"] {
  --color-primary: #15202b;
  --color-primary-light: #152640;
  --color-secondary: #e684af;
  --text-color: #eee;
  --bkg-color: #121212;
}

@media (prefers-color-scheme: dark) {
  /* defaults to dark theme */
  html {
    --text-color: #eee;
    --bkg-color: #121212;
  }
  html[data-theme="light"] {
    --text-color: #222;
    --bkg-color: #FBFCFE;
  }
}

* {
  box-sizing: border-box;
}
.theme__toggle-wrap {
  height: 27px;
  width: 27px;
}
.theme__toggle {
  height: 27px;
  width: 27px;
  position: absolute;
  top: 50%;
  right: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(50%, -50%);
}
.theme__toggle .sun {
  background: var(--color-secondary);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  // border: 4px solid var(--color-primary);
}
.theme__toggle .sun__ray {
  width: 2px;
  background: var(--color-secondary);
  display: block;
  height: 125%;
  position: absolute;
  z-index: -1;
  transition: 0.4s all, height 0.3s ease-in-out;
}
.theme__toggle .sun__ray:nth-child(1) {
  transform: rotate(calc(1 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(2) {
  transform: rotate(calc(2 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(3) {
  transform: rotate(calc(3 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(4) {
  transform: rotate(calc(4 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(5) {
  transform: rotate(calc(5 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(6) {
  transform: rotate(calc(6 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(7) {
  transform: rotate(calc(7 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(8) {
  transform: rotate(calc(8 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(9) {
  transform: rotate(calc(9 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(10) {
  transform: rotate(calc(10 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(11) {
  transform: rotate(calc(11 * calc(360deg / 12)));
}
.theme__toggle .sun__ray:nth-child(12) {
  transform: rotate(calc(12 * calc(360deg / 12)));
}
.theme__toggle:hover .sun__ray:nth-child(1) {
  transform: rotate(calc(calc(1 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(2) {
  transform: rotate(calc(calc(2 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(3) {
  transform: rotate(calc(calc(3 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(4) {
  transform: rotate(calc(calc(4 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(5) {
  transform: rotate(calc(calc(5 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(6) {
  transform: rotate(calc(calc(6 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(7) {
  transform: rotate(calc(calc(7 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(8) {
  transform: rotate(calc(calc(8 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(9) {
  transform: rotate(calc(calc(9 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(10) {
  transform: rotate(calc(calc(10 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(11) {
  transform: rotate(calc(calc(11 * calc(360deg / 12)) - 20deg));
}
.theme__toggle:hover .sun__ray:nth-child(12) {
  transform: rotate(calc(calc(12 * calc(360deg / 12)) - 20deg));
}

.moon {
  height: 18px;
  width: 18px;
  position: absolute;
  background: var(--color-primary);
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: scale(0) translate(25%, -25%);
  z-index: 9;
  transition: 0.4s transform;
  transform-origin: right;
}

[data-theme="dark"] .theme__toggle {
  background-color: var(--color-primary);
}
[data-theme="dark"] .theme__toggle:hover .moon {
  transform: scale(1) translate(-3%, -18%);
}
[data-theme="dark"] .moon {
  transform: scale(1) translate(11%, -11%);
}
[data-theme="dark"] .theme__toggle .sun__ray {
  height: 0;
  transition: 0.4s, transform 0.4s, height 0.2s 0.1s;
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(1) {
  transform: rotate(calc(calc(1 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(2) {
  transform: rotate(calc(calc(2 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(3) {
  transform: rotate(calc(calc(3 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(4) {
  transform: rotate(calc(calc(4 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(5) {
  transform: rotate(calc(calc(5 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(6) {
  transform: rotate(calc(calc(6 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(7) {
  transform: rotate(calc(calc(7 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(8) {
  transform: rotate(calc(calc(8 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(9) {
  transform: rotate(calc(calc(9 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(10) {
  transform: rotate(calc(calc(10 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(11) {
  transform: rotate(calc(calc(11 * calc(360deg / 12)) - 45deg));
}
[data-theme="dark"] .theme__toggle .sun__ray:nth-child(12) {
  transform: rotate(calc(calc(12 * calc(360deg / 12)) - 45deg));
}
