@import "./animation";

$spacers: (
  0: 3,
  1: 6,
  2: 12,
  4: 18,
  3: 20,
);

$props: (
  m: "margin",
  p: "padding",
);

$pos: (
  t: "top",
  b: "bottom",
  l: "left",
  r: "right",
);

// this creates utilities like .mt-3p, .mt-6p, .mt-12p, pt-3p, pt-6p, pt-12p, etc

// this loops through all spacers and props anc creates custom utilities
@each $key, $val in $spacers {
  // loop each properties
  @each $prop_key, $prop_val in $props {
    // loop each position
    @each $pos_key, $pos_val in $pos {
      .#{$prop_key}#{$pos_key}-#{$val}p {
        #{$prop_val}-#{$pos_val}: #{$val}px !important;
      }
    }
    .#{$prop_key}-#{$val}p {
      #{$prop_val}: #{$val}px !important;
    }

    .#{$prop_key}y-#{$val}p {
      #{$prop_val}-bottom: #{$val}px !important;
      #{$prop_val}-top: #{$val}px !important;
    }
    .#{$prop_key}x-#{$val}p {
      #{$prop_val}-left: #{$val}px !important;
      #{$prop_val}-right: #{$val}px !important;
    }
  }
}

$width: (60, 150, 200, 250, 300, 310, 350, 400, 450);

@each $val in $width {
  // loop each properties
  .w-#{$val} {
    width: #{$val}px !important;
  }
}

// max-width utility
$mwidth: (200, 240, 300, 400, 600, 800, 850, 960, 1280);

@each $val in $mwidth {
  // loop each properties
  .mw-#{$val} {
    max-width: #{$val}px !important;
  }
}

.fs-6 {
  font-size: 16px !important;
}
.fs-7 {
  font-size: 12px;
}

.fs-8 {
  font-size: 11px;
}

.fs-9 {
  font-size: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
}

.text-lighter {
  color: #c6c6d3
}

// .flex-grow-1 {
//   flex: 1;
// }

.fw-semibold {
  font-weight: 600 !important;
}

.bg-lighter {
  background-color: $secondary-3;
  color: $neutral-700
}

.text-subtext {
  color: var(--text-subtext);
}

.mh-75v {
  max-height: 75vh;
}

.minh-120 {
  min-height: 120px;
}

.or__divider {
  display: flex;
  align-items: center;
  position: relative;
  gap: 12px;

  &-text {
    position: relative;
  }

  &:after,
  &:before {
    border-bottom: 1px solid;
    border-color: var(--border-input);
    flex: 1;
    content: "";
    height: 0;
  }
}

.rotate-90 {
  transform: rotate(90deg);
  transform-origin: 50% 50%;
  transition: rotate 0.25s;
}

.rotate-180 {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
  transition: transform 0.25s;
}

.rotate__icon {
  transition: transform 0.25s;
}

.note {
  max-width: 600px;
  padding: 20px 28px;
  border-radius: 6px;
  font-size: 16px;
  //color: rgba(111, 111, 144, 0.59);
  white-space: break-spaces;
}

.note.note--clear {
  background: unset !important;
  color: $body-color;

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.bg-purple {
  background: #a976f0;
}

input,
textarea,
.form-control,
.input__text {
  &::placeholder {
    color: $neutral-400;
    font-weight: $font-weight-base;
    font-size: $font-size-base;
  }
}

.products-detial-footer {
  @include media-breakpoint-down(sm) {
    display: block;
    .btn {
      width: 100%;
    }
  }
}

.border-bottom-sm-none {
  @include media-breakpoint-up(sm) {
    border-bottom-width: 0 !important;
  }
}

.lh-1\.5 {
  line-height: 1.5;
}

.rounded-5 {
  border-radius: 20px;
}

.border-sm-end {
  @include media-breakpoint-up(sm) {
    border-right: 1px solid $border-color;
  }
}

.bg-enterprise {
  background-color: #10db65;
}
