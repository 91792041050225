@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  25% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  75% {
    -webkit-transform: scale(0.99);
    transform: scale(0.99);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  25% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  75% {
    -webkit-transform: scale(0.99);
    transform: scale(0.99);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
