
          @import "@assets/scss/config/_index.scss";
// @import "@styles/scss/components/custom-select";
@import '../../../../../assets/scss/components/custom-select';
.studio__thumb {
  height: 58px;
  width: 58px;
}

.profile-detail-form {
  .form-group {
    // margin-bottom: 16px;
    min-width: 90px;
  }

  .text-start {
    margin-bottom: 0;
  }
}

.main-upload-image-wrap .upload-wrap {
  width: 100%;
  height: 300px;
  .icon-cloud {
    font-size: 32px;
  }
}

.image__switch-wrap {
  position: relative;
  &:after {
    background: $secondary-5;
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    left: 5px;
    transform: rotate(45deg) scale(1);
    z-index: 0;
  }
}

.select-projects-option {
  gap: 1rem;
  border: 1px solid $border-color;;
  border-radius: 6px;
  font-size: 16px;
  padding: 20px;
}

.manage-post-type {
  display: flex;
  width: 100%;
  color: #3d4279;
  background-color: $secondary-3;
  padding: 20px;
  
  line-height: 1.3rem;
  font-weight: 400;
  border-radius: 6px;
  margin-top: 10px;

  a {
    color: rgba(111, 111, 144, 0.68);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.project-video-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project-video-wrap {
  border: 1px solid $border-color;
  background-color: $secondary-3;
  border-radius: 6px;
  padding-bottom: 56%;
  position: relative;
  overflow: hidden;
}

.upload-picture-video-block {
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
}

.products-detial-footer {
  align-items: center;
  gap: 1rem;
  border-top: 1px solid $border-color;
}

.add__project {
  .btn__draft {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
