// @import "../../../../assets/scss/components/dropdown";
@import '../../../../../assets/scss/components/dropdown';

.radius-container {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  overflow: visible;
  // z-index: -99;
  .radius-circle {
    position: absolute;
    background: rgba(29, 161, 242, 0.2);
    border-radius: 50%;
    height: 90%;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(29, 161, 242, 0.4);
    @include media-breakpoint-down(sm) {
      height: 275px;
      width: 275px;
    }
  }
}
.mapbox-dot-marker {
  height: 19px;
  width: 19px;
  background: $secondary;
  border-radius: 4px;
}
.mapbox-dot-marker * {
  color: #fff !important;
}
.geo__dropdown {
  .mapboxgl-map {
    height: 350px;
    // right: 15%;
    overflow: hidden;
    max-width: 100% !important;
  }
  .mapbox-marker-user {
    z-index: 4 !important;
  }

  .geo__badge {
    padding: 0px 3px;
    font-family: 'Linotte-Semibold';
  }
  .mapbox-marker-custom {
    p {
      margin-bottom: 0;
    }

    .link {
      height: auto;
    }

    // &:hover {
    //   z-index: 4 !important;
    //   opacity: 100%;
    // }
  }
}

.input-group__alpha {
  // .input-group-text {
  //   background: transparent;
  //   border: none;
  //   // color: #6f6f90ad;
  // }

  .form-control {
    background-color: transparent;
    border: none;
    padding-left: 10%;

    &:focus {
      background-color: transparent !important;
      // border-color: #b5b4fc;
      outline: 0;
      // border: 2px solid #b5b4fc;
    }
  }
}

.geo__distance {
  display: flex;
  flex: 0 0 auto;
  font-weight: $font-weight-bold;
}

.mapboxgl-ctrl__scale {
  color: $neutral-700;
}

.geo__lock {
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.geo_dropdown-top {
  padding: 13px 0;
  position: relative;
  z-index: 9999999999999;
  background-color: $secondary-3;
  // position: absolute;
}

.dropdown__inner {
  overflow: hidden;
}

.geo__slider {
  padding: 20px 40px;
  .rc-slider {
    height: 9px;
    padding: 0;
  }
  .rc-slider-rail {
    background-color: $slider !important;
  }
  .rc-slider-track {
    height: 9px;
    background-color: $slider-filled !important;
  }
  .rc-slider-disabled > .rc-slider-rail {
    z-index: -1;
  }
}
.mapbox-searchicon {
  fill: #6f6f91;
  font-size: $font-size-base;
  position: absolute;
  left: 5%;
}
