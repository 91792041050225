.input__label {
  display: flex;
  position: relative;
  // margin: 12px 0;

  .input__span {
    background-color: $body-bg;
    position: absolute;
    top: 6px;
    padding: 0 6px;
    left: 6px;
    color: $neutral-700;
    transition: transform 0.2s ease;
    pointer-events: none;
    font-weight: 900;
  }

  input,
  textarea {
    &:focus + .input__span {
      transform: translatey(-23px);
      outline: none;
    }
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
  textarea,
  input {
    background-color: $input;
    border: 1px solid $border-color;
    font-weight: 500;
    appearance: none;
    width: 100%;
    font-family: inherit;
    border-radius: 6px;
    padding: 12px;
    height: 84px;
    font-size: $font-size-base;
    transition: transform 0.2s ease;
  }
  textarea {
    padding-top: 24px;
  }
  textarea[rows] {
    height: auto;
  }
}

.form__label {
  font-weight: $font-weight-bold;
  margin-bottom: 8px;
}

// .form-control-xl {
//   height: 54px;
// }

.form-check .form-check-input {
  margin-top: -2px;
}

.form-check {
  padding-left: 2.25em;
}
