.user__settings-content {
  padding: 20px 40px;
  max-width: 600px;
}
.note--attention {
  display: flex;
  max-width: 100%;
  padding: 12px;
  background: $attention;
  color: $darkest;
  border-radius: 4px;
}

@media screen and (min-width: 768px) and (max-width: 990px) {
  .user__settings-content {
    padding: 16px 24px;
  }
}

@include media-breakpoint-down(md) {
  .user__settings-content {
    padding: 16px 0;
  }
}
