.header__links {
  padding: 0.63rem;
  background: $secondary-6;
  border-radius: 46px;
}
.frontend_pages {
  z-index: 999999;
}
.header {
  padding-top: 54px;
  padding-bottom: 42px;
  .icon__btn {
    align-items: center;
    display: flex;
    width: 30px;

    .icon {
      color: $neutral-600;
      font-size: 29px;
    }

    .activity-icon {
      color: rgba(146, 157, 175, 0.6);
    }
  }
}
@include media-breakpoint-down(sm) {
  .header {
    padding-bottom: 19px;
  }
}
.badge--active {
  border-radius: 19px;
  height: 21px;
  cursor: pointer;
  padding: 12px 0px;
}
.active__text {
  margin-left: 3px;
}
.ms-auto.header__right.d-flex.gap-1 > .dropdown {
  position: static;
}
