.user__settings {
  // .user__settings__item
  &__item {
      background-color: $body-bg;
  }
  // .user__settings__icon
  &__icon {
    display: flex;
    width: 70px;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    //color: #b4afb9;
    font-size: 28px;
    font-weight: $font-weight-normal;
  }
}
