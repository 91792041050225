@import '~bootstrap/scss/modal';
.crypto__list {
  .crypto__icon {
    width: 46px;
    height: 46px;
    margin-right: 6px;
    padding: 6px;
  }
}
.linked__item--bank {
  background: $secondary-5;
  border-radius: 6px;
}
.linked__country {
  background: $secondary-5;
  border-radius: 3px;
}
.linked__bank {
  letter-spacing: 0.01rem;
}
.linked__account {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0.02rem;
}
