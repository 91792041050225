
          @import "@assets/scss/config/_index.scss";
@import '~bootstrap/scss/accordion';

.accordion-button:not(.collapsed) {
  box-shadow: none;
}
.faq__accordion * {
  transition: none !important;
}
.faq__accordion {
  border-radius: 24px;
  overflow: hidden;
  .accordion-button {
    padding-left: 0;
    padding-right: 0;
  }

  .accordion-body {
    padding-left: 0;
    padding-right: 0;
  }
}
