.radio__toggle-input {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;

}

.radio__toggle-label {
  align-items: center;
  // border-radius: 6px;
  height: 32px;
  border-radius: 22px;


  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  padding: 8px 12px;
  position: relative;
  font-size: 12px;
  font-weight: $font-weight-bold;

  &.active {
    .dot {
      width: 0;
      height: 0;
      opacity: 0;
      margin: 0;
      padding: 0;
    }
  }

  .dot {
    border-radius: 50%;
    height: 1em;
    width: 1em;
    margin-right: 6px;
    opacity: 1;
    transition: all 0.25s;


    //
    background: $secondary-5 !important;
    border: 2px solid $primary;
    width: 20px;
    height: 20px;
  }
}
