.billing__value {
  text-align: end;
  line-height: .75rem;
  display: flex;
  flex-direction: column;
  gap: .5rem
}
.icon--bg {
  width: 19px;
  height: 19px;
  min-height: 19px;
  min-width: 19px;
  margin-right: 3px;
  margin-left: -4px;
  padding-left: 6px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: $secondary-3;
}
.similar__item__wrap:last-child {
  border: none !important;
}
.similar__ {
  // .similar__item__wrap
  &item__wrap {
    &.active {
      background-color: $secondary-3;
      border-radius: 6px;
    }
  }

  // .similar__item__main
  &item__main {
    flex: 1;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  // .similar__item__name
  &item__name {
    line-height: 1rem;
    font-weight: $font-weight-extra-bold;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }

  &item__location {
    color: #aaa9c2;
    font-weight: $font-weight-bold;
    font-size: 11px;
  }

  &item__price {
    font-weight: $font-weight-bold;
    color: $price;
  }
}

.qty__tag {
  // background: $neutral-100;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  color: $neutral-700;
  font-weight: $font-weight-semibold;
  border-radius: 4px;
}
