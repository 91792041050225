
          @import "@assets/scss/config/_index.scss";
.cart {
  &__page {
    .logo__wrap {
      @include media-breakpoint-down(sm) {
        border-bottom: 1px solid var(--border-input);
        padding: 20px;
        margin: 0 -20px;
        min-height: 80px;
      }
    }

    .btn__remove {
      min-height: 6px !important;
      color: var(--text-subtext);
      &:hover {
        color: var(--text-danger);
      }
    }

    .qty__input {
      border: unset;
      flex: 0;
      font-size: 16px;
      font-weight: $font-weight-bold;
      text-align: center;
      min-height: 42px;
      border-radius: 3px;
      width: 49px;
    }
    .qty__input:focus-visible {
      outline: none;
    }
  }

  &__steps {
    .btn {
      color: var(--text-subtext);
    }
    .active {
      color: var(--text-color);
    }
  }
}
.avatar__checkout {
  width: 76px !important;
  height: 76px !important;
  min-width: 76px !important;
}
.no-bg {
  background-color: unset;
  border: unset;
}
.cart_controller {
  width: 200px;
}
@include media-breakpoint-down(sm) {
  /* .list__item-img {
    width: 75px;
  }*/
  .cart_controller {
    width: 100%;
  }
}
