.onboarding {
  &__icon {
    font-size: 36px;
  }
}
.item__image-wrap {
  width: 46px;

  @include media-breakpoint-down(sm) {
    width: 36px;
  }
}

.order__widget {
  padding: 24px;
  border: 1px solid $border-color;
  border-radius: 8px;
}
.drag-text {
  font-size: inherit;
}
.type__icon {
  color: #64a9ee;
  height: 49px;
  width: 49px;
  background: $secondary-5;
  border-radius: 6px;
}
.dropdown-item,
.dropdown-item:focus,
.dropdown-item:hover {
  color: inherit !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: hsl(217deg 15% 78% / 15%) !important;
}
.receipt__item {
  background: $secondary-5;
  border-radius: 21px;
}
.post__title {
  width: 175px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}