.item__total-wrap {
  background: $secondary-5;
  padding: 16px 12px;
  font-weight: $font-weight-bold;
  font-size: 16px;
  border-radius: 6px;
}
.item__image-wrap {
  width: 46px;

  @include media-breakpoint-down(sm) {
    width: 36px;
  }
}
.product__type {
  font-size: 26px !important;
  img {
    height: 26px
  }
}
.order__widget {
  padding: 24px;
  border: 1px solid $border-color;
  border-radius: 8px;
}
.dropdown-item,
.dropdown-item:focus,
.dropdown-item:hover {
  color: inherit !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: hsl(217deg 15% 78% / 15%) !important;
}
.note--tax {
  border-radius: 46px !important;
  padding: 9px 19px !important;
}