.donate__close-btn,
.donate__prev-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.donate__prev-btn {
  right: auto;
  left: 10px;
}

.donation__radio > .option__item {
  position: relative;
  width: calc(100% / 3);
  margin-top: -1px;
  margin-left: -1px;
  border: 1px solid $border-color !important;
}

.donation__radio input[type="radio"] {
  width: 100%;
  height: 90px;
  opacity: 0;
}

.donation__radio input[type="radio"]:not([disabled]) {
  cursor: pointer;
}

.donation__radio label {
  position: absolute;
  font-weight: 600;
  font-size: 16px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: 0.2s cubic-bezier(1, 0.27, 0.26, 1);
}
.donation__radio input:checked + label {
  background: $info;
  color: white;
  font-weight: 700;
}

.donation__radio [disabled] + label {
  cursor: default;
  color: #b6b6b6;
}

.avatar__wrap {
  border-radius: 32px;
}

.donation__type {
  input[type="radio"] {
    height: 35px;
  }
  &-radio {
    height: 35px;
    font-size: 12px;
    flex: 1;
  }

  label {
    line-height: 35px;
  }
}

.donation__review {
  height: 90px;
  font-size: 26px;
}

.paypal-logo {
    height: 22px;
}

.btn__checkout.debit-credit {
    background: #2C2E2F;
    color: $neutral-100;
}

.paypal-powered-by {
    text-align: center;
    font-size: 11px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    color: #7b8388;
    position: relative;
    margin-right: 3px;
    bottom: 3px;
}

.powered-logo {
    height: 16px;
    margin-left: 6px;
}