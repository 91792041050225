
          @import "@assets/scss/config/_index.scss";
// @import "@styles/scss/components/list-table";
@import '../../../../../assets//scss/components/list-table';

.admin__billing-value {
  min-width: 60px;
  max-width: 60px;
  @include media-breakpoint-down(sm) {
    order: 2;
  }
}
.admin__tax-table {
  .btn__wrap {
    background: $secondary-5;
    border-radius: 46px;
    padding: 0 21px
  }
  @include media-breakpoint-down(sm) {
    .btn__wrap {
      flex: 1;
    }

    .text__wrap {
      flex: 1;
    }
  }
}
.szh-accordion__item-btn {
  text-align: start !important;
  display: flex;
  align-items: center;
  // padding-right: 1rem;
}
li.table__list-item.table__list-item--tax {
  border-bottom: 1px solid whitesmoke;
}
li.table__list-item--tax:first-child {
  border-top: 2px dashed #e0e5ed;
  padding-top: 1.3rem !important;
  margin-top: 1.3rem !important;
}
li.table__list-item--tax:last-child {
  margin-bottom: 0.9rem !important;
}
.dropdown-item,
.dropdown-item:focus,
.dropdown-item:hover {
  color: inherit !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: hsl(217deg 15% 78% / 15%) !important;
}
