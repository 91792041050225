
          @import "@assets/scss/config/_index.scss";
.widget__title {
  font-size: 16px;
  font-weight: $font-weight-extra-bold;
  a {
    color: #56577b;
    font-size: 16px;
    margin-bottom: 20px;
  }
}
