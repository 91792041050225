
          @import "@assets/scss/config/_index.scss";
.user__detail {
  .user__img-wrap {
    position: relative;
  }

  .user__img {
    height: 200px;
    width: 100%;
    border-radius: 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .user__img-content {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    flex: 1;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .tab__menu {
    align-items: center;
  }

  &-wrap {
    > .tab-content {
      @include media-breakpoint-up(sm) {
        // border-left: 1px solid var(--border-input);
      }
    }
  }
}

.tab-pane.fade:not(.show) {
  display: none;
}

@media screen and (max-width: 990px) and (min-width: 575px) {
  .desktop .tab__menu {
    width: 75px;
  }

  .user__detail .user__img {
    margin: 0 6px;
    height: 130px;
  }
  .user__detail-wrap > .tab-content {
    padding: 20px 40px;
  }
}

@media screen and (min-width: 990px) {
  .tab__menu {
    width: 200px;
    padding-bottom: 3.3rem;
  }

  .user__detail-wrap > .tab-content {
    padding: 50px 70px;
  }
}

.mobile-tab {
  padding: 16px 0;

  .toggle__btn {
    background: $secondary-5;
    color: $neutral-700;
    border-radius: 9px;
    border: 1px solid $border-color;
    height: 65px;
    padding: 1rem;
    text-decoration: none;
    width: 100%;
  }
  .tab__icon {
    margin-right: 12px;
    width: 26px;
  }
}
