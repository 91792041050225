.list__table {
  position: relative;
  overflow: visible;
  flex: 0 0 auto;
  // border-bottom: 1px solid $border-color;
  // border-left: 1px solid $border-color;
  border-radius: 6px;
  list-style: none;
  overflow: hidden;
  border: 1px solid $border-color;

  &__footer {
    background-color: $secondary-5 !important;
  }
}
.list__table-list {
  // background: $secondary-5;
  list-style: none;
}
.history__list-item:last-child {
  border: none !important;
}
.list__table-sort {
  background: $secondary-3;
  margin-right: -1px;
  margin-left: -1px;
  padding-right: 21px;
  padding-left: 21px;
  align-items: center;
  // background: $secondary-5;

  // border: 1px solid $border-color;
  // border-top-left-radius: 4px;
  // border-top-right-radius: 4px;
}

.btn.btn__sort {
  color: #3a94d4;
  padding-top: 14px;
  padding-bottom: 14px;
  padding: 12px 18px;
  font-size: 14px;
  font-weight: 700;
}

.list__img-badge {
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 21px;
  height: 21px;
  margin-top: -5px;
  margin-right: -5px;
  padding-top: 3px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: $secondary;
  color: $neutral-100;
  font-size: 11px;
  font-weight: 700;
}

.qty__tag {
  //background-color: $neutral-100;
  border-radius: 4px;
}

.category__link {
  .category__img-wrap {
    margin: 6px 9px 6px 6px;
    padding: 9px 0;
    flex: 0 0 auto;
    height: 34px;
    max-height: 34px;
    max-width: 34px;
    min-height: 34px;
    min-width: 34px;
  }
  img {
    height: 26px;

  }
}

.billing__buttons {
  gap: 8px;
}

/* .progress__wrap {
    max-width: 300px;
  
    @include media-breakpoint-down(md) {
      width: 200px;
    }
  }*/

.table__list-item + .table__list-item {
  border-top: 1px solid $border-color !important;
}

.billing__name {
  width: 150px;

  @include media-breakpoint-down(sm) {
    width: auto;
  }
}

@include media-breakpoint-down(sm) {
  .post__value {
    order: 1;
  }

  .qty__tag {
    order: 1;
  }
}
