
          @import "@assets/scss/config/_index.scss";
@font-face {
  font-family: 'Linotte-Heavy';
  src: url('../fonts/Linotte/Linotte-Heavy.otf') format('opentype'),
    url('../fonts/Linotte/Linotte-Heavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Linotte-Bold';
  src: url('../fonts/Linotte/Linotte-Bold.otf') format('opentype'),
    url('../fonts/Linotte/Linotte-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Linotte-Light';
  src: url('../fonts/Linotte/Linotte-Light.otf') format('opentype'),
    url('../fonts/Linotte/Linotte-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Linotte-Regular';
  src: url('../fonts/Linotte/Linotte-Regular.otf') format('opentype'),
    url('../fonts/Linotte/Linotte-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Linotte-Semibold';
  src: url('../fonts/Linotte/Linotte-Semibold.otf') format('opentype'),
    url('../fonts/Linotte/Linotte-Semibold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* body {
  font-family: 'Linotte-Light' !important;
} */

// .error {
//   font-size: 90%;
//   margin-top: 2px;
//   color: #dc3545 !important;
//   background: yellow
// }
.error {
  font-size: 90%;
  margin: 16px 0;
  // margin: 0 !important;
  padding: 6px 9px;
  color: $error;
  background: $error-bg;
  font-weight: 400;
}
.input__wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  // margin: 1.6rem 0px 0.6rem;
}
.inputerror {
  border-color: #dc3545 !important;
}

.inputerrorLable {
  color: #dc3545 !important;
}

.inputerror:focus {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

/*#pageLoader {
  position: fixed;
  opacity: 1;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999999999999999 !important;
  background: url('../../../src/assets/images/loader2.gif') 50% 50% no-repeat rgb(255, 255, 255);
}*/

.react-confirm-alert-blur {
  -webkit-filter: unset !important;
  filter: unset !important;
  filter: unset !important;
  -webkit-filter: unset !important;
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999999;
  background: hsl(0deg 0% 100% / 45%) !important;
  -webkit-animation: react-confirm-alert-fadeIn 0.2s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.2s 0.2s forwards;
}
// .react-confirm-alert-overlay,
// .react-confirm-alert-overlay-custom {
//   position: fixed;
//   height: 100%;
//   width: 100%;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 999999
// }
.react-confirm-alert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.react-confirm-alert-body {
  // font-size: 16px;
  color: inherit;
  font-family: inherit;
  padding: 40px 60px;
  text-align: left;
  background: $secondary-5;
  border-radius: 6px;
  box-shadow: -7px 7px 20px 10px #0000000d;
  max-width: 500px;
}

.react-confirm-alert-body > h1 {
  margin-bottom: 1.3rem;
  // text-decoration: underline;
}

.react-confirm-alert-button-group {
  margin: 2.3rem auto 0;
  display: flex;
  justify-content: end;
}

.react-confirm-alert-button-group > button {
  z-index: 9999999999999 !important;
  font-size: 16px;
  padding: 12px 45px;
  font-weight: 900;
  border-radius: 4px;
}

.react-confirm-alert-button-group > button:first-child {
  background-color: unset;
  border: none;
  color: inherit;
  padding: 12px 21px;
}

.react-confirm-alert-button-group > button:last-child {
  border: none;
  border-radius: 46px;
  background-color: $info;
  // padding: 12px 32px !important;
  color: $neutral-100;
}

.react-confirm-alert-button-group .frntlogout {
  background-color: #f10065 !important;
  z-index: 9999999999999 !important;
}

.modal-backdrop {
  background-color: #00000078 !important;
}

.btnWarning {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: capitalize;
  font-family: 'Public Sans', sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(33, 43, 54) !important;
  background-color: rgb(255, 193, 7) !important;
  /* width: 100% !important; */
  box-shadow: rgb(255 193 7 / 24%) 0px 8px 16px 0px;
}

.btnWarning:hover {
  text-decoration: none;
  background-color: rgb(183, 129, 3) !important;
  box-shadow: rgb(145 158 171 / 20%) 0px 2px 4px -1px, rgb(145 158 171 / 14%) 0px 4px 5px 0px,
    rgb(145 158 171 / 12%) 0px 1px 10px 0px;
}

.btnDanger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: capitalize;
  font-family: 'Public Sans', sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(255, 72, 66) !important;
  /* width: 100%; */
  box-shadow: rgb(255 72 66 / 24%) 0px 8px 16px 0px !important;
}

.btnDanger:hover {
  text-decoration: none !important;
  background-color: rgb(183, 33, 54) !important;
  box-shadow: rgb(145 158 171 / 20%) 0px 2px 4px -1px, rgb(145 158 171 / 14%) 0px 4px 5px 0px,
    rgb(145 158 171 / 12%) 0px 1px 10px 0px;
}

/* .homepage .item {
  position: relative;
  margin: 0 auto;
  height: 29vw;
}
.homepage .owl-item img {
  display: block;
  width: 100%;
  position: absolute;
  object-fit: cover;
  height: 100%;
} */
.owl-item img {
  display: inline-block;
  max-width: auto;
  height: 500px !important;
  margin-bottom: 30px;
}

.item-media.item-media-4by3 {
  padding-bottom: 25%;
}

.item-media.item-media-4by3 a {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-bottom: 100%;
  position: absolute;
  top: 0;
  bottom: auto;
}

.pace-running .pace-Inactive {
  display: none !important;
}

.pace-running .pace-progress {
  display: none !important;
  background: transparent !important;
  transform: unset !important;
  height: 0 !important;
}

.pace-running .pace-activity {
  display: none !important;
  height: 0 !important;
  border: transparent !important;
  animation: unset !important;
}

.modal-content {
  max-height: 90%;
  // align-items: center;
  justify-content: center;
  background-color: $secondary-5 !important;
  overflow: auto;
  position: absolute !important;
  left: -50% !important;
  top: 50% !important;
  transform: translate(50%, -50%) !important;
  width: 100% !important;
}
.modal-lg {
  height: 95%;
}

/* .setting-nav .nav-link.active {
  color: $neutral-100;
  cursor: default;
  background-color: #00AB55 !important;
}
.setting-nav .nav-link{
  color: #00AB55;
}

.settingButton {
  margin-left: -10px;
} */
// .text-primary {
//   color: #00ab55 !important;
// }

.hover-scale-up:hover {
  transform: scale(1.02);
  z-index: 1;
}

.img-circle {
  border-radius: 50%;
}

.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.card-primary.card-outline {
  border-top: 3px solid #007bff;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: $neutral-100;
  background-color: $primary !important;
}

.desktop__tab .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: $primary !important;
  background-color: $secondary-3 !important;
  // border: 1px solid transparent !important;
  border-radius: 4px;
  transition: none !important;
}

// .nav-link,
// .nav-link:hover {
//   color: #00ab55;
// }

.img100px {
  width: 100px !important;
}

.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: none;
  color: #585a83;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 52px;
  margin: 0;
  font-size: 12px;
  border: 1px solid $border-color;
  min-width: 150px;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid $border-color;
  background: hsl(0, 0%, 90%);
  color: black;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}

.ReactTags__selected span.ReactTags__tag:first-child {
  margin-left: 0px;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

.ReactTags__suggestions {
  position: absolute;
  top: 60px;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: $neutral-100;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid $border-color;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: $secondary-5;
  cursor: pointer;
}

.ReactTags__remove {
  border: none !important;
  cursor: pointer !important;
  background: none !important;
  color: black !important;
}

.ReactTags__tagInputField {
  display: block;
  width: 100%;
  font-weight: 600;
  line-height: 1.25;
  color: $neutral-700;
  background-color: $secondary-3;
  background-clip: padding-box;
  border: 1px solid $border-color;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: calc(1.25em + 1.5rem + 2px);
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  border-radius: 4px;
}

.ReactTags__tagInputField::placeholder {
  color: $neutral-700;
  font-weight: 400;
  font-size: 14px;
}

.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
}

.ReactTags__tagInput {
  order: 1;
  flex: 0 0 100%;
  display: flex;
  margin-bottom: 15px;
}

.ReactTags__tagInputField {
  /* flex: 0 0 80%; */
  margin-right: 10px !important;
}

.ReactTags__clearAll {
  flex: 0 0 19%;
  margin: 0;
  padding: 0;
}

span.tag-wrapper.ReactTags__tag {
  order: 2;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 6px 3px 6px 9px;
  border-radius: 4px;
  font-size: 14px;
  background: #3898ec;
  color: white;
  font-weight: 600;
  border: none;
  text-transform: lowercase;
}

button.ReactTags__remove {
  margin-left: 5px !important;
  color: white !important;
  padding: 0px;
  width: 30px;
  font-size: 20px;
}

.ReactTags__remove:hover {
  /*background-color: #FFBDAD !important;*/
  color: #de350b !important;
}

.ReactTags__remove:focus,
.ReactTags__clearAll:focus {
  outline: unset;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.grid img {
  max-width: 100%;
  border-radius: 6px;
}

.input-group {
  /* display: flex; */
  align-content: stretch;
}

.input-group > input {
  flex: 1 0 auto;
}

/**
 * Even when I set some dimension-related styles on this
 * element but not on the input or button, they'll still
 * line up.
 */
.input-group-addon11 {
  background: #eee;
  border: 1px solid #ccc;
  padding: 0.5em 1em;
}

.pricing-plan li {
  border-bottom: 1px dotted #ededed;
  font-size: 16px;
}

.pricing-plan {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 50px;
}

.pricing-plan .card-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}

.card li {
  padding: 8px 0;
}

.set-price {
  background: #f4f6f8 !important;
  color: $neutral-600;
}

.starter {
  background: #62c5ff !important;
  color: #ffffff;
}

.advanced {
  background: #ffb62f !important;
  color: #ffffff;
}

.business {
  background: #10db65 !important;
  color: #ffffff;
}

.--switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 31px;
}

.--switch input {
  display: none;
}

.--slider .fa-check {
  color: #ffffff;
  position: absolute;
  left: 15px;
  font-size: 16px;
  display: none;
}

.--slider .fa-times {
  color: #ffffff;
  position: absolute;
  font-size: 16px;
  right: 15px;
}

.--slider {
  position: absolute;
  cursor: pointer;
  display: flex;
  background-color: $toggle;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: transform 0.4s;
  transition: transform 0.4s;
  border-radius: 50px;
}

.--slider:before {
  position: absolute;
  content: '';
  height: 27px;
  width: 27px;
  left: 2px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.--switch input:checked + .--slider .fa-check {
  display: block;
}

.--switch input:checked + .--slider .fa-times {
  display: none;
}

.--switch input:checked + .--slider {
  background-color: #4bd863;
}

.--switch input:focus + .--slider {
  box-shadow: 0 0 1px #4bd863;
}

.--switch input:checked + .--slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.sw-card__content {
  padding: 30px;
  background-clip: padding-box;
  border-radius: 4px;
  border: 1px solid #d1d9e0;
  position: relative;
}

.sw-settings__content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 30px 10px;
}

.sw-settings-item {
  color: #52667a;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sw-settings-item .sw-settings-item__icon.background--enabled {
  background-color: #eaedf1;
}

.sw-settings-item .sw-settings-item__icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 20px;
}

#insta {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 45px;
  /* change this to change the size*/
}

.activity__dropdown,
.cart__dropdown,
.profile__dropdown,
.geo__dropdown {
  position: absolute !important;
  inset: 0 0 auto auto !important;
  overflow: visible !important;
  -webkit-transform: translate(-4px, 30px) !important;
  transform: translate(-4px, 30px) !important;
}

@include media-breakpoint-down(sm) {
  .profile__dropdown {
    transform: translate(0px, 65px) !important;
  }
  .activity__dropdown {
    transform: translate(57px, 60px) !important;
  }
  .cart__dropdown {
    transform: translate(103px, 60px) !important;
  }
}

.invoice table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

.invoice table td,
.invoice table th {
  padding: 15px;
  background: #eee;
  border-bottom: 1px solid #fff;
}

.invoice table th {
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
}

.invoice table td h3 {
  margin: 0;
  font-weight: 400;
  color: #3773c6;
  font-size: 1.2em;
}

.invoice table .qty,
.invoice table .total,
.invoice table .unit {
  text-align: right;
  font-size: 1.2em;
}

.invoice table .no {
  color: $neutral-100;
  font-size: 1.6em;
  background: #3773c6;
}

.invoice table .unit {
  background: #ddd;
}

.invoice table .total {
  background: #3773c6;
  color: $neutral-100;
}

.invoice table tbody tr:last-child td {
  border: none;
}

.invoice table tfoot td {
  background: 0 0;
  border-bottom: none;
  white-space: nowrap;
  text-align: right;
  padding: 10px 20px;
  font-size: 1.2em;
  border-top: 1px solid #aaa;
}

.invoice table tfoot tr:first-child td {
  border-top: none;
}

.invoice table tfoot tr:last-child td {
  color: #3773c6;
  font-size: 1.4em;
  border-top: 1px solid #3773c6;
}

.invoice table tfoot tr td:first-child {
  border: none;
}

.invoice footer {
  width: 100%;
  text-align: center;
  color: #777;
  border-top: 1px solid #aaa;
  padding: 8px 0;
}

a.MuiListItemButton-root.active:focus {
  color: #6b68f8 !important;
}

.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #ffffff !important;
  border: 1px solid rgb(117 111 228) !important;
  background-color: rgb(117 111 228) !important;
}

.filelabel {
  width: 120px;
  border: 2px dashed grey;
  border-radius: 5px;
  display: block;
  padding: 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.filelabel i {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
}

.filelabel i,
.filelabel .title {
  color: grey;
  transition: 200ms color;
}

.filelabel:hover {
  border: 2px solid #756fe4;
}

.filelabel:hover i,
.filelabel:hover .title {
  color: #756fe4;
}

#FileInput {
  display: none;
}

.input__span,
.input__label textarea:focus + .input__span {
  color: $neutral-700;
  transform: translatey(-23px) !important;
  outline: none;
}

.basic-single {
  width: 100%;
}

.my-modalbank {
  width: 90vw !important;
  max-width: 90vw !important;
}

.react-confirm-alert-body {
  width: unset !important;
}

.btn-check:checked + .btn,
.btn-check:active + .btn,
.btn:active,
.btn.active {
  box-shadow: unset !important;
}

.ssninstruction {
  display: none;
}

.ssnlable:hover .ssninstruction {
  display: block;
}

.sold--item {
  position: absolute !important;
  // -webkit-transform: translate(50%, -50%) !important;
  // -ms-transform: translate(50%, -50%) !important;
  width: 90% !important;
}

.sleeve {
  flex: 1 1 0;
  margin: auto;
}

.credit-card {
  margin: 20px;
  display: flex;
  flex-shrink: 0;
  background: #3bf;
  position: relative;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5), inset 1px 1px 0 rgba(255, 255, 255, 0.3);
  width: 337px;
  height: 212px;
  /* opacity: .5; */
  @include media-breakpoint-down(sm) {
    width: 90%
  }
}

.credit-card .card-company {
  position: absolute;
  width: 60px;
  height: 45px;
  /* top: 20px; */
  left: 20px;
  background: linear-gradient(135deg, #ddccf0 0%, #d1e9f5 44%, #f8ece7 100%);
  border-radius: 8px;
}

/* &.selected {
    transform: scale(1.1);
    z-index: 2;
    opacity: 1;
    box-shadow: 0 2px 10px rgba(0,0,0,.5), 0 10px 50px 5px rgba(0,0,0,.25), inset 1px 1px 0 rgba(255,255,255,.3);
  
  }
  
  
  &:before {
    content: '';
    border-radius: inherit;
    position: absolute;
    display: block;
    top: 0;left: 0;bottom: 0;right: 0;
    background: linear-gradient(135deg, rgba(255,255,255,@.5) 0%, rgba(0,0,0,@.5) 100%);
  }
} */

.card-type,
.card-company {
  text-align: right;
  text-transform: uppercase;
  margin: 10px;
  color: rgba(0, 0, 40, 0.5);
}

.card-company {
  font-family: Georgia, Garamond;
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.3);
  font-weight: normal;
  padding: 10px;
  background: #c32;
  color: #fb0;
  letter-spacing: 1px;
  text-align: center;
  margin: 15px 0;
}

.card-number,
.card-expire,
.card-holder {
  display: flex;
  margin: 5px;
  justify-content: center;
  font-family: monospace;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.digit-group {
  margin: 5px;
}

.card-expire,
.card-holder {
  font-size: 16px;
  justify-content: flex-start;
  padding: 0 20px;
}

.card-text {
  font-size: 12px;
  font-family: sans-serif;
  color: #000;
  color: rgba(0, 0, 40, 0.5);
  text-shadow: none;
  margin: 3px;
  margin-left: 10px;
}

.card-type {
  margin-top: auto;
  font-size: 14px;
}

.card-p-text:after {
  content: '***';
}

.plans .plan input[type='radio'] {
  position: absolute;
  opacity: 0;
}

.plans .plan {
  cursor: pointer;
  width: 25%;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plans .plan .plan-content img {
  margin-right: 30px;
  height: 72px;
}

.plans .plan .plan-details span {
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
  line-height: 24px;
}

.container .title {
  font-size: 16px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  margin-bottom: 20px;
}

.plans .plan .plan-details p {
  font-size: 14px;
  line-height: 18px;
}

.plans .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type='radio']:checked + .plan-content:after {
  /* content: ""; */
  position: absolute;
  height: 8px;
  width: 8px;
  background: #216fe0;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
  box-shadow: 0px 0px 0px 2px #0066ff;
}

.plans .plan input[type='radio']:checked + .plan-content {
  border: 2px solid #216ee0;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

@media screen and (max-width: 991px) {
  .plans {
    margin: 0 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 40px;
  }

  .plans .plan {
    width: 100%;
  }

  .plan.complete-plan {
    margin-top: 20px;
  }

  .plans .plan .plan-content .plan-details {
    width: 70%;
    display: inline-block;
  }

  .plans .plan input[type='radio']:checked + .plan-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@media screen and (max-width: 767px) {
  .plans .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
}

@media screen and (max-width: 540px) {
  /*  .activity__dropdown {
    transform: translatey(75px) !important;
  }
  .cart__dropdown {
    transform: translatey(75px) !important;
  }*/
  .plans .plan .plan-content img {
    margin-bottom: 20px;
    height: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .plans .plan input[type='radio']:checked + .plan-content:after {
    top: 20px;
    right: 10px;
  }

  .plans .plan .plan-content .plan-details {
    width: 100%;
  }

  .plans .plan .plan-content {
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

li.search__tag {
  flex: 0;
  white-space: nowrap;
  background: #6f6e91;
  position: relative;
  height: 48px;
  font-weight: 600;
  padding: 6px 16px;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  border-radius: 5px;
  list-style: none;
  background-image: none;
  box-shadow: none;
  color: white;
}

.search__tag span {
  text-transform: lowercase;
}
.search__container input {
  background: #ffffff00 !important;
  margin: 0 !important;
  padding: 0 !important;
}
.search__container input:focus {
  outline: none !important;
  text-decoration: none !important;
  border: none !important;
}
.search__container li a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-decoration: none;
  color: rgba(0, 0, 0, 0);
}

.search__container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  /* position: absolute; */
  width: 100%;
  overflow: hidden;
}

.search__container li a:hover {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background-image: url(http://svgshare.com/i/3yv.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#suggestion {
  width: inherit;
  height: inherit;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 18px;
  color: #d1c7c7;
  margin-left: 34px;
  margin-top: 7px;
}

.tag.tag--ongoing._2 {
  border-radius: 50%;
}

.tag.tag--ongoing {
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 50%;
  font-size: 21px;
}

.tag {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 6px;
  font-size: 9px;
  font-weight: 700;
}
.mapboxgl-user-location-dot:after {
  border-radius: 50%;
  border: 2px solid #fff;
  content: '';
  height: 19px;
  left: -2px;
  position: absolute;
  top: -2px;
  width: 19px;
  box-sizing: border-box;
  box-shadow: 0 0 3px rgb(0 0 0 / 35%);
}

.mapboxgl-user-location-dot:before {
  content: '';
  position: absolute;
  animation: mapboxgl-user-location-dot-pulse 2s infinite;
}

.mapboxgl-user-location-dot,
.mapboxgl-user-location-dot:before {
  background-color: #1da1f2;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.rank__avatar {
  width: 98px;
  height: 98px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.react-mapbox-ac-input {
  margin-bottom: unset !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
  /*  .geo__dropdown {
    inset: 0px auto auto 0px !important;
    transform: translate3d(-204px, -18px, 0px) !important;
  }*/
}

/* .imgremove-btn:hover {
    box-shadow: 0px 0px 3px grey;
    transition: all .3s ease-in-out;
}
.imgremove-btn {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
 
  top: -8px;
  right: 0p;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
} */

.img-wrap {
  position: relative;
  display: inline-block;
  /* border: 1px red solid; */
  font-size: 0;
}

.img-wrap .close {
  position: absolute;
  /* top: 2px;
  right: 2px; */
  top: -19px;
  right: -16px;
  z-index: 1;
  padding: 5px 2px 2px;
  color: #cd5c5c;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.6;
  text-align: center;
  font-size: 49px;
  line-height: 10px;
  border-radius: 50%;
}

.img-wrap:hover .close {
  opacity: 1;
}

.charity_avatar_bg {
  background-size: contain !important;
}

.donor_avatar_bg {
  background-size: cover !important;
}

// canvas {
//   position: absolute;
//   top: -2000px;
// }

.saleReceipt {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 30px 0px;
  cursor: pointer;
}

.saleReceipt .close {
  position: absolute;
  right: 0;
  font-size: 30px;
  color: red;
  top: 0;
  cursor: pointer;
}
