
          @import "@assets/scss/config/_index.scss";
.fundraiser__grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px; /* Adjust as needed */
  }
  
  @media (max-width: 992px) {
    .fundraiser__grid{
      grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .fundraiser__grid{
      grid-template-columns: repeat(1, 1fr); /* 1 column for small screens */
    }
  }
  .fundraise__img {
    border-radius: 0;
    box-shadow: none;
    display: block;
    height: auto;
    margin: 0;
    -o-object-fit: cover;
    object-fit: cover;
    order: 1;
    width: 100%;
    transition: transform .3s ease;
  }