
          @import "@assets/scss/config/_index.scss";
.gallery__container {
  gap: 0.6rem;
  display: flex;
  width: 100%;
  padding-bottom: 0%;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  display: grid;
  overflow: visible;
  max-width: 100%;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-auto-rows: minmax(auto, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
 // grid-template-rows: minmax(auto, 1fr) minmax(auto, 1fr);  

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
