
          @import "@assets/scss/config/_index.scss";
.grab__dropdown {
  background: $neutral-100;
  position: absolute !important;
  //inset: 0px 0px auto auto !important;
  transform: translate(1px, 50px) !important
}
.empty__grab {
  &-img {
    font-size: 90px;
    line-height: 1;

    svg {
      height: 1em;
      width: 1em;
    }
  }

  &-content {
    color: $neutral-700;
  }

  &-msg {
    color: $neutral-600;
  }
}

.grab__content {
  background: #fff;
  padding: 16px 12px;

  .grab__badge {
    background-color: #4dc59e;
    border-radius: 10px;
    color: #fdfdfd;
    position: absolute;
    right: -2px;
    top: -2px;
    display: inline-block;
    font-size: 9px;
    font-weight: 600;
    line-height: 1;
    margin-left: 6px;
    padding: 5px 8px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
  }
}

.box__icon.lottie__icon {
  margin-top: -6px;
}