
          @import "@assets/scss/config/_index.scss";
.item__total-wrap {
  background: $secondary-5;
  padding: 16px 12px;
  font-weight: $font-weight-bold;
  font-size: 16px;
  border-radius: 6px;
}
.item__image-wrap {
  width: 46px;

  @include media-breakpoint-down(sm) {
    width: 36px;
  }
}

.order__widget {
  padding: 24px;
  border: 1px solid $border-color;
  border-radius: 8px;
}
