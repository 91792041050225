
          @import "@assets/scss/config/_index.scss";
.project__detail-img {
  height: auto;
  position: relative;
  max-height: 600px;
  max-width: 400px;
  min-height: 400px;
  order: 2;
  padding: 1.9rem;
  // background-color: $neutral-300;
  border-radius: 16px;
  img {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
  }
  @include media-breakpoint-down(sm) {
    min-height: 320px;
    max-height: 320px;
    display: flex;
    align-items: center;
    justify-content: center
  }
  margin: 0;
  justify-content: center;
  flex: 0 0 auto;
}
