.icon__hide {
  color: #dd4646;
}
.filelabel {
  .hidden {
    display: none;
  }
}
// .note {
//   max-width: 600px;
//   padding: 20px 28px;
//   border-radius: 6px;

//   line-height: 1.3rem;

//   &.note--inputs {
//     max-width: 400px;
//     margin-bottom: 12px;
//   }
// }
.list-group {
  color: $neutral-700 !important;
}
.deactivate {
  display: flex;
  padding: 24px 2.3rem;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  border-radius: 8px;

  .list--deactivate {
    display: flex;
    flex-direction: column;
    gap: .3rem;
    padding-top: 1.6rem;
    padding-bottom: 0.9rem;
    padding-left: 1.3rem;
  }

  .list__item {
    padding-top: 0.3rem;
    padding-bottom: 0.6rem;
  }

  .btn--deactivate {
    color: #ec4040;
    &:hover {
      text-decoration: underline;
    }
  }
}

.post__video {
  position: relative;
  overflow: hidden;
  padding-bottom: 56%;
  flex: 1;
  border: 1px solid #000;
  border-radius: 6px;
  background-color: $secondary-3;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
