
          @import "@assets/scss/config/_index.scss";
@include media-breakpoint-down(md) {
  .ladder__name {
    width: 90px;
  }
}
@include media-breakpoint-down(sm) {
  .ladder__name {
    width: 45px;
  }
}
