
          @import "@assets/scss/config/_index.scss";
// import our custom variables
@import './config';

// load bootstrap styles
//@import "~bootstrap/scss/maps";
@import '~bootstrap/scss/utilities';

@import '~bootstrap/scss/bootstrap';
// @import "~bootstrap/scss/root";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/containers";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/helpers";
// @import "~bootstrap/scss/buttons";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/forms";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/dropdown";

// @import "~bootstrap/scss/utilities/api";
@import '../../View/frontEnd/Layout/Home/style.scss';
// base styles
@import './base';

@import '../../assets/css/style.scss';

// @import '../../View/frontEnd/Component/organisms/header/style.scss';
// @import '../../View/frontEnd/Component/organisms/footer/style.scss';
// @import '../../View/frontEnd/Component/atoms/list-item-img/style.scss';

// component styles
@import './components/badge';
@import './components/button';
@import './components/forms';
@import './components/ladder-menu';
@import './components/list-table';
@import './components/dropdown';

// layout styles
@import './layout/grid';

// load fonts
@import './fonts';

// load utility styles
@import './utils';

[data-theme='dark'] {
  // load variables, mixins and functions
  @import './config-dark';

  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  // // load bootstrap styles
  // //@import "~bootstrap/scss/maps";
  @import '~bootstrap/scss/utilities';
  //
  // @import '~bootstrap/scss/root';
  // @import '~bootstrap/scss/reboot';
  // @import '~bootstrap/scss/transitions';
  // @import '~bootstrap/scss/type';
  // @import '~bootstrap/scss/images';
  // @import '~bootstrap/scss/containers';
  // @import '~bootstrap/scss/grid';
  // @import '~bootstrap/scss/helpers';
  // @import '~bootstrap/scss/buttons';
  // @import '~bootstrap/scss/button-group';
  // @import '~bootstrap/scss/forms';
  // @import '~bootstrap/scss/progress';
  // @import '~bootstrap/scss/dropdown';
  //desktop__tab
  // @import '~bootstrap/scss/utilities/api';

  // base styles
  @import './base';

  @import '../../assets/css/style.scss';
  @import '../../View/frontEnd/reset-password/style.scss';
  @import '../../View/frontEnd/forgot-password/style.scss';
  @import '../../View/frontEnd/about/style.scss';
  @import '../../View/frontEnd/admin-detail/style.scss';
  @import '../../View/frontEnd/user-detail/style.scss';
  @import '../../View/frontEnd/apply/style.scss';
  @import '../../View/frontEnd/trust/style.scss';
  @import '../../View/frontEnd/login/style.scss';
  @import '../../View/frontEnd/checkout/style.scss';
  @import '../../View/frontEnd/register/style.scss';
  @import '../../View/frontEnd/Layout/Home/style.scss';
  @import '../../View/frontEnd/order-confirmation/style.scss';
  @import '../../View/frontEnd/donation-confirmation/style.scss';
  @import '../../View/frontEnd/Component/organisms/header/style.scss';
  @import '../../View/frontEnd/Component/organisms/header-help/style.scss';
  @import '../../View/frontEnd/Component/organisms/add-post/style.scss';
  @import '../../View/frontEnd/Component/organisms/admin-posts/style.scss';
  @import '../../View/frontEnd/Component/organisms/admin-settings-tab/style.scss';
  @import '../../View/frontEnd/Component/organisms/add-project/style.scss';
  @import '../../View/frontEnd/Component/organisms/add-crowdfunding/style.scss';
  @import '../../View/frontEnd/Component/organisms/item-detail-main/style.scss';
  @import '../../View/frontEnd/Component/organisms/footer/style.scss';
  @import '../../View/frontEnd/Component/organisms/product/style.scss';
  @import '../../View/frontEnd/Component/organisms/posts-table/style.scss';
  @import '../../View/frontEnd/Component/organisms/filter-dropdown/style.scss';
  @import '../../View/frontEnd/Component/organisms/activity/style.scss';
  @import '../../View/frontEnd/Component/organisms/history/style.scss';
  @import '../../View/frontEnd/Component/organisms/history-list/style.scss';
  @import '../../View/frontEnd/Component/organisms/wishlist/style.scss';
  @import '../../View/frontEnd/Component/organisms/payment-method/style.scss';
  @import '../../View/frontEnd/help/style.scss';
  @import '../../View/frontEnd/crowdfunding-detail/style.scss';
  @import '../../View/frontEnd/Component/organisms/user-tabs/style.scss';
  @import '../../View/frontEnd/Component/organisms/share-widget/style.scss';
  @import '../../View/frontEnd/Component/organisms/organization-widget/style.scss';
  @import '../../View/frontEnd/Component/organisms/user-billing/style.scss';
  @import '../../View/frontEnd/Component/organisms/user-admin/style.scss';
  @import '../../View/frontEnd/Component/organisms/shopping-cart/style.scss';
  @import '../../View/frontEnd/Component/organisms/suggested-list/style.scss';
  @import '../../View/frontEnd/Component/organisms/settings-tabs-admin/style.scss';
  @import '../../View/frontEnd/Component/molecules/suggestion-wrapper/style.scss';
  @import '../../View/frontEnd/Component/organisms/summary-content/style.scss';
  @import '../../View/frontEnd/Component/organisms/project-detail-main/style.scss';
  @import '../../View/frontEnd/Component/organisms/profile-settings/style.scss';
  @import '../../View/frontEnd/Component/organisms/user-items/style.scss';
  @import '../../View/frontEnd/Component/organisms/tax-table/style.scss';
  @import '../../View/frontEnd/Component/organisms/user-tax/style.scss';
  @import '../../View/frontEnd/Component/organisms/geo-location/style.scss';
  @import '../../View/frontEnd/Component/organisms/settings-tabs/style.scss';
  @import '../../View/frontEnd/Component/organisms/user-billing/style.scss';
  @import '../../View/frontEnd/Component/molecules/faq/style.scss';
  @import '../../View/frontEnd/Component/molecules/user-settings-item/style.scss';
  @import '../../View/frontEnd/Component/molecules/following-item/style.scss';
  @import '../../View/frontEnd/Component/molecules/marquee-list/style.scss';
  @import '../../View/frontEnd/Component/molecules/donate-modal/style.scss';
  @import '../../View/frontEnd/Component/molecules/history-item/style.scss';
  @import '../../View/frontEnd/Component/molecules/org-crowdfunding-project-item/style.scss';
  @import '../../View/frontEnd/Component/molecules/linked-org/style.scss';
  @import '../../View/frontEnd/Component/atoms/radio-toggle/style.scss';
  @import '../../View/frontEnd/Component/atoms/list-item-img/style.scss';
  @import '../../View/frontEnd/Component/atoms/category-checkbox/style.scss';

  // layout styles
  @import './layout/grid';

  // component styles
  @import './components/button';
  @import './components/badge';
  @import './components/forms';
  @import './components/ladder-menu';
  @import './components/list-table';
  @import './components/dropdown';
  // layout styles
  @import './layout/grid';

  // load fonts
  @import './fonts';

  // load utility styles
  @import './utils';
}
