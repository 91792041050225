
          @import "@assets/scss/config/_index.scss";
.ourbrand__item {
  width: 120px;
  min-width: 90px;
  max-height: 120px;
  padding: 1.3rem;
}
.ourbrand__img {
  max-height: 62px;
}
.section--cityscape {
  position: relative;
}
.cityscape {
  position: absolute;
  left: 0;
  top: auto;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.03;
}
.circle,
.bigcircle,
.about__label {
  background: $secondary-5;
}
.circle {
  position: absolute;
  left: auto;
  top: 0;
  right: 8%;
  bottom: auto;
  z-index: -1;
  width: 154px;
  height: 154px;
  border-radius: 50%;
}
.circle.circle--small {
  top: 6%;
  right: 16%;
  width: 90px;
  height: 90px;
}
.bigcircle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 190px;
  height: 190px;
  min-width: 190px;
  min-height: 190px;
  padding: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  @include media-breakpoint-down(sm) {
    width: 90px;
    height: 90px;
    min-width: 90px;
    min-height: 90px;
    padding: 0
  }
}
.bigcircle._2 {
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
@include media-breakpoint-down(sm) {
  .bigcircle._2 {
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
  }
  .section--abouthero {
    margin: 0;
  }
}
.section--abouthero {
  overflow: hidden;
  position: relative;
  width: 100%;
  //max-width: 1440px;
  //margin-right: auto;
  //margin-bottom: 2.3rem;
  // margin-left: auto;
  padding-right: 6%;
  //padding-bottom: 2.3rem;
  padding-left: 6%;
  //padding-top: 6%;
  margin: 3rem 0;
  padding: 3rem 0;
}
.section--design {
  margin-top: 6%;
  @include media-breakpoint-down(sm) {
    margin-top: 0 !important;
  }
}
.section--design:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  left: 0;
  height: 100%;
  width: 150%;
  background: $secondary-5;
  transform: rotate(315deg);
  transform-origin: bottom;
}
.section--cityscape {
  background: $secondary-5;
}
.about__label {
  margin-left: -0.9rem;
  padding: 9px 16px;
  background-color: $secondary-3;
  color: #6b68f8;
  font-weight: 600;
}
.about {
  .about__title {
    line-height: 0.875;
  }

  .crypto__icon {
    height: 36px;
    width: 36px;
  }
}

.about__step {
  width: 64px;
  height: 64px;
}

.about__step-icon {
  height: 120px;
}

.video__frame {
  position: relative;
  padding-top: 56%;

  .video__iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.crypto__icon-24 {
  height: 24px;
  img {
    height: 100%;
  }
}

.page__circle {
  width: 95px;
  height: 95px;

  img {
    width: 100%;
  }
}

.seperator {
  height: 2px;
  border: 2px dashed #e6e6e6;
}
