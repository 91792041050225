
          @import "@assets/scss/config/_index.scss";
.btn__follow {
  font-size: 21px !important;
  width: 40px;
}

.btn__remove-follow {
    font-size: 16px !important;
}
// .wishlist__item__name {
//   color: rgba(var(--bs-dark-rgb),var(--bs-text-opacity))!important
// }