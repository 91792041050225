
          @import "@assets/scss/config/_index.scss";
.footer__category-title {
  margin-bottom: 12px;
  font-size: $font-size-base;
  line-height: 36px;
}
.footer__links {
  max-height: 250px;
  display: flex;
  gap: 9px;
  flex-direction: column;
  flex-wrap: wrap;
}
.footer__link {
  color: #8a8aab;

  &:hover {
    color: #6f6f90ad;
    text-decoration: underline;
  }
}

.footer__link-item {
  margin-bottom: 8px;
  font-size: $font-size-base;
}
