.item__total-wrap {
  background: $secondary-5;
  padding: 16px 12px;
  font-weight: $font-weight-bold;
  font-size: 16px;
  border-radius: 6px;
}
.szh-accordion__item-btn {
  text-align: start !important;
  display: flex;
  align-items: center;
  // padding-right: 1rem;
}
.szh-accordion:not(:last-child) {
  border-bottom: 1px solid $border-color;
}
.table__list--content:first-child {
  margin-top: 0.3rem;
}
