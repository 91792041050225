.tab__nav {
  flex-wrap: nowrap !important;
}
.tab__btn {
  cursor: pointer;
  color: $neutral-700 !important;
  font-size: 20px;
  font-weight: $font-weight-bolder;
  // border: 1px solid transparent;
  // line-height: 1;
  padding: 21px 52px !important;
  justify-content: start;

  &.active {
    background-color: $secondary-3;
    // border-color: var(--border-input);
  }

  &:hover {
    text-decoration: none;
  }
}
.tab__icon {
  display: flex;
  font-size: 19px;
  width: 26px;
}

.desktop__tab .tab__text {
  display: none;
}
@include media-breakpoint-up(md) {
  .tab__btn {
    justify-content: center;
  }
}
@include media-breakpoint-down(sm) {
  .tab__nav > * a:not(.active) {
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 990px) {
  .desktop__tab .tab__icon {
    margin-right: 12px;
  }

  .desktop__tab .tab__text {
    display: block;
  }
}
@media screen and (min-width: 576px) {
  /*.tab__btn {
  margin: 3px 0
}*/
}
