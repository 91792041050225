@import '../../../../../assets/scss/components/list-table';

.post__value {
  min-width: 60px;
  max-width: 60px;
  display: flex;
  flex-direction: column;
  gap: 6px
}
.project--btn {
  background: unset;
  color: inherit;
}
.table__list-item:last-child {
  border: none !important;
}
.progress__wrap {
  width: 100%;
  max-width: 300px;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}
.post__status {
  // background: $secondary-5;
  font-size: 12px !important;
  padding: 6px 3px !important;
  border-radius: 6px;
  color: $neutral-dark;
  text-align: center;
}
.post__status--draft {
  background: rgb(255, 214, 66);
}
.post__status--done {
  background: rgb(43, 219, 93);
}
