.lottie__icon:not(.no-color-inherit) {
  svg,
  path {
    fill: currentColor;
    stroke: currentColor;
  }
}

.lottie__icon {
  height: 1em;
  width: 1em;
}

.filter__dropdown {
  &.dropdown-top-arrow:after {
    left: 20px !important;
    background-color: $secondary-3;
  }
  &.dropdown-menu {
    background-color: $body-bg !important;
    width: 475px;
    top: 32px !important;
    // overflow: visible !important;
  }
  // .filter__dropdown-hd
  &-hd {
    padding: 2px 18px 12px;
    background-color: $secondary-3;
  }

  // &-bd {
  //   background-color: $secondary-3;
  // }

  .filter__checkboxes {
    padding: 6px 0;
  }
}

.tax__icon {
  //border: 2px solid;
  font-size: calc(1em - 4px);
  border-radius: 3px;
}
.rc-slider-rail,
.rc-slider-track {
  background-color: $slider !important;
}
.rc-tooltip {
  .rc-tooltip-inner {
    background: #727296;
    padding: 3px 5px;
    line-height: 1;
  }

  .rc-tooltip-arrow {
    border-top-color: #727296;
  }
}
.filter__slider {
  padding: 16px 40px 40px;
  height: 95px;
  display: flex;
  align-items: end;
  .rc-slider-mark {
    margin-top: 12px;
  }
  .rc-slider-mark-text {
    color: #aaa9c2;
    font-size: 10px;
  }
  .rc-slider-rail,
  .rc-slider-track {
    position: absolute;
    height: 8px !important;
    border-radius: 6px;
  }
  .rc-slider-step {
    height: 8px;
  }
  .rc-slider-handle {
    height: 21px;
    width: 21px;
    background-color: $primary;
    border: solid 2px $primary;
    opacity: 1;
    margin-top: -7px;
  }
  .rc-slider-dot {
    display: none;
  }
}

.toggle__btn {
  display: flex;
  justify-content: center;
  // height: 46px;
}

.toggle__btn--filters {
  border-radius: 50rem !important;
}
@include media-breakpoint-down(sm) {
  .toggle__btn--filters,
  .toggle__btn {
    border-radius: 6px !important;
    height: 56px;
  }

  .filter__slider {
    order: 1;
  }
  .filter__dropdown,
  .geo__dropdown {
    &:after {
      display: none;
    }
  }
  .filter__dropdown,
  .grab__dropdown,
  .geo__dropdown {
    flex-direction: column;
    &.dropdown-menu {
      --x: 0;
      --y: 0;
      --z: 0;
      background: $body-bg;
      padding: 0;
      border-radius: 12px 12px 0 0;
      display: flex !important;
      position: fixed !important;
      bottom: 0 !important;
      top: auto !important;
      right: 0 !important;
      width: 100% !important;
      left: 0 !important;
      transform: translate3d(var(--x), var(--y), var(--z)) !important;
      transition: 0.35s cubic-bezier(0.33, 1, 0.53, 1) !important;
      overflow: hidden;
      z-index: 99999999;
      .category__item {
        width: 33.333%;
        border-color: $border-color;
      }

      &:not(.show) {
        --y: 150%;
        transition: 0.5s cubic-bezier(0.33, 1, 0.53, 1) !important;
      }
    }

    .filter__dropdown-hd {
      order: 2;
      padding-top: 12px;
    }

    .filter__dropdown-bd {
      order: 3;
    }
  }

  .grab__dropdown.show {
    .dropdown__inner {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  .selected__overlay:not(.hidden) {
    visibility: visible;
    background: rgb(0 0 0 / 28%);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.2s cubic-bezier(0.33, 1, 0.53, 1);
    opacity: 1;
    z-index: 9999999;
  }
}
