.note__info {
  display: flex;
  max-width: 100%;
  padding: 16px 20px;
  align-items: center;
  list-style-type: none;
}

.input-group.donate__control {
  width: 105px;

  .form-control {
    border-left: 0;
    background: $neutral-100;
    border-color: $border-color;
    padding-left: 0;
    margin: 0 10px;
  }
}

.organization__cart-btn {
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: auto;
  }
}
.donate-section,
.geo_dropdown-top {
  .input-group-text {
    background: $secondary-4;
    border: 1px solid $border-color;
    color: $neutral-700;
  }
}
.geo_dropdown-top {
  .input-group-text {
    border: none !important;
  }
}
