
          @import "@assets/scss/config/_index.scss";
.help__banner {
  background-image: url("../../../../../assets/images/city.svg"),
    -webkit-gradient(linear, left top, left bottom, from(#314fe7), to(#314fe7));
  background-position: 50% 100%, 0 0;
  background-size: cover, auto;
  background-repeat: no-repeat, repeat;

  @include media-breakpoint-down(sm) {
    padding: 50px 0;
  }
}

.help__search-wrap {
  max-width: 940px;
}
