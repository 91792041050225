// @import '@styles/scss/components/list-table';
@import '../../../../../assets/scss/components/list-table';
.szh-accordion__item-btn {
  background: unset;
  border: unset;
  color: inherit;
  width: 100%;
  text-align: start !important;
  display: flex;
  align-items: center;
  // padding-right: 1rem;
}
.chev-wrapper {
  height: 46px;
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-fluid.accordion-sublist > li {
  border-bottom: 1px solid $border-color;
}
.container-fluid.accordion-sublist > li:first-child {
  margin-top: 1.3rem;
}
.tax__left {
  min-width: 300px;
  @include media-breakpoint-up(md) {
    width: auto;
    // max-width: 100%;
}
}

li.table__list-item.table__list-item--tax {
  border-bottom: 1px solid $border-color;
}
li.table__list-item--tax:first-child {
  border-top: 2px dashed $border-color;
  padding-top: 1.3rem !important;
  margin-top: 1.3rem !important;
}
li.table__list-item--tax:last-child {
  margin-bottom: 0.9rem !important;
}