.history__list > li:nth-last-child(2) {
  border: unset !important;
}
#history-filter-select-form-control {
  margin: 0 !important;
  padding: 4px 0 !important;
  text-align: center;
  vertical-align: center !important;
}
@media screen and (max-width: 990px) {
  .history__avatar {
    display: none !important;
  }
}
.accordion__head {
  height: 48px;
}
.order__id {
  width: 250px;
}

.order__logo {
  display: flex;
  align-items: center;
  height: 35px;
  width: 35px;
}
.img--nobg {
  background: unset !important;
  border: unset;
}
.chevron-rotate {
  transform: rotate(180deg);
}
#history-filter-select-form-control {
  fieldset {
    border-color: #3a94d4;
  }
  svg {
    color: #3a94d4;
  }
  div > div {
    color: #3a94d4;
  }
}
.makeStyles-list-175 li {
  color: red
}