// @import "@styles/scss/components/custom-select";
@import '../../../../../assets/scss/components/custom-select';

.react-mapbox-ac-suggestion:hover {
  background-color: $body-bg;
}
.mapboxgl-map {
  max-width: 100% !important;
}
.studio__thumb {
  height: 58px;
  width: 58px;
}

.price-group-wrap {
  .form-group {
    display: flex;
    flex-direction: column;
  }
}
.post__accordion-header {
  border-bottom: 1px solid $border-color;
  .accordion__btn {
    padding: 20px 0;

    cursor: pointer;
    background-color: transparent;
    border: none;
    line-height: 1;
  }

  .accordion__icon {
    transition: transform 0.25s;
  }
}

.profile-detail-form {
  .form-group {
    // margin-bottom: 16px;
    min-width: 90px;
  }

  .text-start {
    margin-bottom: 0;
  }
}

.main-upload-image-wrap .upload-wrap {
  width: 100%;
  height: 300px;
  .icon-cloud {
    font-size: 32px;
  }
}
.image-upload-wrap {
  background-color: $secondary-3;
}
.upload-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 112px;
  background-color: #e5f4ff;
  border: 2px dashed rgba(62, 170, 255, 0.58);
  border-radius: 9px;
  position: relative;

  &:hover .icon-cloud {
    transform: scale(0.8);
    opacity: 0.3;
  }
  label {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
  }

  .icon-cloud {
    color: #5f6982;
    font-size: 20px;
    transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  }

  label input {
    display: none;
    cursor: pointer;
  }
}

.upload-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.studio__input--quantity {
  max-width: 72px;
}

.image__switch-wrap {
  position: relative;
  &:after {
    background: $secondary-5;
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: -30px;
    left: 5px;
    transform: rotate(45deg) scale(1);
    z-index: 0;
  }
}

.sub__categories-dropdown {
  width: 100%;
}

.select-projects-option {
  gap: 1rem;
  border: 1px solid $border-color;;
  border-radius: 6px;
  font-size: 16px;
  padding: 20px;
}

.manage-post-type {
  display: flex;
  width: 100%;
  color: #3d4279;
  background-color: #f8fafd;
  padding: 20px;
  
  line-height: 1.3rem;
  font-weight: 400;
  border-radius: 6px;
  margin-top: 10px;

  a {
    color: rgba(111, 111, 144, 0.68);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.project-video-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project-video-wrap {
  border: 1px solid $border-color;
  background-color: #f8fafd;
  border-radius: 6px;
  padding-bottom: 56%;
  position: relative;
  overflow: hidden;
}

.upload-picture-video-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.fulfilling-check-wrap {
  .form-check {
    display: flex;
    align-items: center;
    min-height: auto;
    margin: 0;
  }
.policy__input {
  padding: 12px
}
  .form-check .form-check-input {

    border: 2px solid #4e8dff;
    cursor: pointer;
  }

  .form-check-input[type='checkbox'] {
    border-radius: 0.25em;
  }
}
#category,
#subcategory  {
  padding: 1rem 2rem;
}
.fulfilling-check-wrap .form-check {
  align-items: flex-start;
  gap: 1rem;
}

.products-detial-footer {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-top: 1px solid $border-color;
}
