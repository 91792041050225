
          @import "@assets/scss/config/_index.scss";
.btn.btn__icon-lg {
  padding: $btn-padding-y-lg;
  font-size: 1.5em;

  .icon__wrap {
    justify-content: center;
  }
}
.btn__content {
  line-height: initial;
}
