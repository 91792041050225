.checkout__list li:last-child {
  border: none !important;
}
.checkout__tax {
  background: $secondary-5;
  border-radius: 4px;
  height: 42px;
  min-width: 42px;
  img {
    height: 26px;
  }
}
.checkout__legend {
  background: $secondary-1;
  border-radius: 4px;
  img {
    height: 19px;
  }
}
.checkout__subtotal--price {
  width: 85px;
}
@include media-breakpoint-down(sm) {
  .checkout__subtotal--price {
    width: auto;
  }
}
