.ladder__menu {
  position: relative;
  border-bottom: 3px solid $primary;
  border-radius: 0px;
  &-item {
    font-size: $font-size-base;
  }
}
.ladder__ul {
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
  box-shadow: 1px 4px 7px 0px #00000012 !important;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transform: translateY(0%);
}

.ladder__ul.active {
  background: $secondary-5;
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  max-height: 560px;
  overflow: auto;
  z-index: 999;
  border-radius: 6px;
  box-shadow: unset !important;
  border: 1px solid $border-color;
  margin-top: -1px;
}

.ladder__dropdown--selected {
  align-items: center;
  background-color: $secondary-3;
  font-size: $font-size-base;
  display: flex;
  padding: 14px 16px;
  cursor: pointer;
  border-radius: 6px;
  white-space: nowrap;
  height: 48px;
  @include media-breakpoint-down(sm) {
    height: 56px;
  }
}

.ladder__icon {
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 12px;
  img {
    width: 100%;
  }
}

.ladder__selected {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 14px;
}

.ladder__ul li {
  padding: 1rem;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  min-height: 60px;
  border-bottom: 1px solid var(--border-input);
}

.ladder__ul li.active {
  background-color: $neutral-200;
  font-weight: 700;
}

.ladder__total {
  font-weight: 900;
  font-size: 14px;
  color: #a3c778;
  margin-left: 12px;
  margin-right: 12px;
}

.icon--showall {
  color: #03a9f4;
  font-size: 16px;
  width: 36px;
}

.ladder__icon > .icon {
  font-size: 19px;
}

.ladder__mark--open {
  background: #8bc34a;
}
.ladder__mark--funded {
  background: #ff9c40;
}

.ladder__mark {
  min-width: 19px;
  height: 19px;
  border-radius: 50%;
  margin-right: 16px;
}
