.cart__button {
  border-radius: 54px !important;
  height: 38px !important;
  min-height: auto !important;
}
.badge--example {
  color: $neutral-700 !important;
  padding: 0;
  // border-radius: 19px !important;
  // padding: 9px 12px !important;
  // background: #605ef8
}
.incart--button {
  height: 34px;
  width: 34px;
}
.progress {
  background-color: $progress;
}
.wish__check {
  opacity: 0;
  position: absolute;
  &:checked + .wish__icon svg {
    fill: rgb(246, 100, 97);
    stroke: rgb(246, 100, 97);
    animation: bounce 250ms none;
    animation-timing-function: ease-in-out;
  }

  &:not(:checked) + .wish__icon svg {
    fill: transparent;
  }
}

.wish__label {
  cursor: pointer;
}

.wish__icon {
  font-size: 20px;
  svg {
    width: 1em;
  }
}
.div-block-32 {
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  position: relative;
}
.div-block-36 {
  grid-column-gap: 6px;
  flex-flow: column;
  padding-right: 9px;
  line-height: 1.15em;
  display: flex;
}
.product {
  // box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 9px;
  color: #9796b1;
  min-width: 245px;

  // .product__header
  &__header {
    background: #22908f;
    height: 36px;
    border-radius: 6px 6px 0 0;
  }
  &__order {
    height: 106px;
  }
  &__actions {
    height: 60px;
  }
  // .product__top
  &__top {
    font-size: 21px;
    font-weight: 700;
  }
  &__mid {
    background: #ededff;
    border-radius: 12px;
  }
  // .product__footer
  &__footer {
    border-radius: 0 0 6px 6px;
    height: 46px;
    padding: 6px 12px;
  }

  // .product__org
  &__org {
    right: 12px;
    top: 21px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgb(215, 215, 241);
    // height: 32px;
    text-align: center;

    .org__img {
      width: 40px;
      max-height: 100%;
    }
  }

  // .product__left
  &__left {
    z-index: 9;
  }
  &__left,
  &__right {
    width: 30%;
    height: 100%;
  }

  // .product__mid
  &__mid {
    position: relative;
    width: 100%;
    height: 90%;
    // max-width: 35%;
    margin: auto;
    flex-direction: column;
    flex: 0 auto;
  }

  // .product__columns
  &__columns {
    border-radius: 0px !important;
    padding: 0 12px;
    height: 142px;
    // background: $secondary-5;
  }

  // .product__img-wrap
  &__img-wrap {
    height: 90px;
    width: 90px;
    border-radius: 50%;
  }

  &__img {
    max-height: 100px;
  }

  // .product__title
  &__title {
    line-height: 1.1;
    margin-bottom: 0;
  }

  // .product__price
  &__price {
    //color: #45bb82;
    font-size: 16px;
    font-weight: 900;
    border-radius: 12px;
  }

  // .product__details
  &__details {
    // height: 40px;
    // background: $secondary-5;
    position: relative;
    border-radius: 9px;
    // padding: 12px;
  }
  // .product__header
  &__date {
    font-size: 12px;
    font-weight: 600;
  }
  // .product__category
  &__category {
    // .product__category-icon
    &-icon {
      background-color: #22908f;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      padding: 5.5px;
      display: flex;
      align-items: center;
    }
  }

  // .product__subcategory, .product__count
  &__subcategory,
  &__count {
    font-size: 14px;
    font-weight: 600;
  }
  // .product__location
  &__location {
    font-weight: 600;

    white-space: nowrap;
    bottom: 0px;
    right: 0;
    .icon {
      margin-right: 6px;
    }
  }

  // .product__meta
  &__meta {
    gap: 12px;
    .icon {
      font-size: 21px;
      line-height: 1;
      text-align: center;
    }
  }
  // .product__type-tax
  &__tax img {
    height: 26px !important;
  }

  .btn__sold {
    padding: 2px 6px !important;
    border: 0 !important;
    margin-top: 0px !important;
    line-height: initial;
    height: auto;
    background: #37cc5e !important;
    color: #fff !important;
    &:hover {
      color: inherit;
      background: inherit;
    }
    .sold__icon {
      margin-right: 3px;
      height: 12px;
    }
    span {
      height: 15px;
    }
  }
}

.item__bg.feature {
  background: rgb(0 198 255 / 20%);
  flex-grow: inherit;
  border-radius: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.feature__header {
  z-index: 99;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 21px;
  padding-left: 21px;
  font-weight: 500;
  display: flex;
  position: relative;
}
.feature__img {
  z-index: 1;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  // height: 300px;
  margin-top: auto;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  max-width: 100%;
}
.feature__bg {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-flow: column;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 14%;
  left: 0%;
  right: 0%;
  .line {
    background-color: rgba(36, 72, 228, 0.07);
    width: 55%;
    height: 24px;
  }
}
.feature__price {
  background-color: $secondary-4;
  border-radius: 24px;
  padding: 3px 16px;
  font-weight: 500;
}
.feature__btn {
  position: absolute;
  top: auto;
  bottom: -3%;
  left: auto;
  right: 0%;
  border-radius: 50% !important;
  padding: 0 !important;
  color: $neutral-100 !important;
  height: 56px;
  width: 56px;
  font-size: 36px;
  z-index: 9999;
}
