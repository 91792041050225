
          @import "@assets/scss/config/_index.scss";
.menu__title {
    display: flex;
    padding: 12px;
    flex-direction: row;
    flex: 0 0 auto;
    border-bottom: 1px solid $border-color;
    color: $neutral-700;
}

.notify__settings-item {
    font-size: 12px;
    color: #9f9db1;
    height: 56px;
    padding: 0 12px;
}

.menu__toggle {
    align-items: center;
    display: flex;
}

.notifications-wrap {
    max-height: 325px;
    overflow: auto;
}