
          @import "@assets/scss/config/_index.scss";
.trust__thumb {
  height: 75px;

  img {
    min-width: 75px;
    height: 100%;
  }

  &-wrap {
    flex-shrink: 0;
    height: 130px;
    width: 150px;

    @include media-breakpoint-down(sm) {
      width: auto;
    }
  }
}

.trust__note {
  max-width: 100%;
}

.about__item {
  width: 98px;
  flex-direction: column;
  flex: 0 0 auto;

  @include media-breakpoint-down(sm) {
    width: auto;
  }
}

.about__list {
  @include media-breakpoint-down(sm) {
    justify-content: space-between;
  }
}

.about__img {
  width: 46px;
}

.about__banner {
  padding: 76px 1.3rem 46px;
}

.about__bubble {
  position: absolute;
  left: 0;
  top: -32%;
  right: 0;
  bottom: auto;
  width: 100px;
  height: 100px;
  margin-right: auto;
  margin-left: auto;
  padding: 24px;
  border-radius: 50%;
  background-color: #f5f2f3;
  box-shadow: 0 -1px 3px 0 rgb(0 0 0 / 5%);
}
