
          @import "@assets/scss/config/_index.scss";
.similar__ {
  // .similar__item__main
  &item__main {
    flex: 1;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  // .similar__item__name
  &item__name {
    line-height: 1rem;
    font-weight: $font-weight-extra-bold;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }

  &item__location {
    color: #aaa9c2;
    font-weight: $font-weight-bold;
    font-size: 12px;
  }

  &item__price {
    font-weight: $font-weight-bold;
    color: $price;
  }
}

.qty__tag {
  //background: $neutral-100;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  color: $neutral-700;
  font-weight: $font-weight-semibold;
  border-radius: 4px;
}
.btn__sold {
  padding: 2px 6px !important;
  border: 0 !important;
  margin-top: 0px !important;
  line-height: initial;
  height: auto;
  background: #37cc5e !important;
  color: #fff !important;
  &:hover {
    color: inherit;
    background: inherit;
  }
  .sold__icon {
    margin-right: 3px;
    height: 12px;
  }
  span {
    height: 15px;
  }
}
