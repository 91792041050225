$body-bg: #fff;
$list: #fff;
$input: #fff;
$primary: #2448e4;
$secondary: #5F5DF8;
$slider: #96dbfa;
$progress: #f8fafd;
$slider-filled: #abe2fb;
$toggle: #efefef;
$attention: #fffbdf;

$secondary-1: #f8fafd;
$secondary-2: #f0f2ff;
$secondary-3: #f7f6ff;
$secondary-4: #eff4ff;
$secondary-5: #f7f6ff;
$secondary-6: #fff;

$neutral-100: #ffffff;
$neutral-200: #fafafc;
$neutral-300: #f7f7fa;
$neutral-400: #e5e5ea;
$neutral-500: #80809d;
$neutral-600: #6e6d8f;
$neutral-700: #3a395e;
$neutral-dark: #3a395e;

$placeholder: #6e6d8f;
$product-shadow: #f1f1f100;

$font-family-sans-serif: 'Jcfonts linotte', sans-serif;

$body-color: $neutral-700;
$body-bg: #fbfcfe;

$darkest: #001429;
$dark: $neutral-700;
$light: $neutral-500;
$border-color: #e6e6e6;
$hr-border-color: #e6e6e6;

$font-size-base: 1.125rem; // 18px
// $font-size-base: 0.875rem; // 14px
$font-size-lg: 1.125rem; // 18px / 16px
// $font-size-lg: 1rem; // 16px
$font-size-sm: 0.75rem; // 12px

$small-font-size: 85%;
$text-muted: $neutral-500;

$h1-font-size: $font-size-base * 3.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.4285;
$h5-font-size: $font-size-base * 1.14285;
$h6-font-size: $font-size-base;

$lead-font-weight: 400;
$headings-font-weight: 700;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$input-font-weight: $font-weight-semibold;
$input-border-color: $border-color;
$input-group-addon-bg: $secondary-4;
$input-btn-font-size-sm: 0.75rem;
$input-btn-font-size: 0.875rem;

$line-height-base: 1.25;
$line-height-lg: 1.25;

$input-btn-padding-y: 0.58rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 1.25rem;

//Adjust button padding size globally:
$btn-padding-y: 21px;
$btn-padding-x: 56px;

$btn-padding-y-sm: 9px;
$btn-padding-x-sm: 26px;

$btn-padding-y-lg: 10px;
$btn-padding-x-lg: 20px;

$btn-font-size-sm: 18px; // Font size

$btn-font-size: 20px; // Font size
$btn-font-weight: 700; // Font weight

// Custom variable for button border radius
$btn-border-radius: 46px; // Border radius
$btn-border-radius-sm: 46px; // Border radius

$btn-disabled-opacity: 0.5;
$input-btn-border-width: 1px;

$border-radius: 3px;
$border-radius-lg: 4px;

$neutral: #6b68f8;
$success: #47ce5e;
$price: #3a395e;
$warning: #f8b22f;
$danger: #f15e5e;
$info: #2448e4;
$infinity: #5f5df8;
$error: #e61b1b;
$error-bg: #ffe2e2;
$btn-primary-color: #fff; // Text color

$light: $neutral-100 !default;
$dark: $neutral-700 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'price': $price,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'infinity': $infinity,
  'ldanger': #fd7a7a,
  'error': $error,
  'error-bg': $error-bg
) !default;

$progress-height: 0.75rem;
$progress-border-radius: 35px;

$font-weight-normal: 500;
$font-weight-medium: 600;
$font-weight-semibold: 700;
$font-weight-bold: 900;

$grid-gutter-width: 2.5rem;

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: $spacer * 0.5,
    2: $spacer,
    3: $spacer * 1.5,
    4: $spacer * 2,
    5: $spacer * 2.5
  ),
  $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1305px,
  xxl: 1305px
) !default;

$navbar-padding-x: 0;

$caret-vertical-align: middle;
$caret-spacing: 0.4rem;

// badge
$badge-font-size: 10px;
$badge-font-weight: $font-weight-semibold;
$badge-padding-y: 0.4em;
$badge-border-radius: 2px;
$badge-padding-x: 1em;

$enable-shadows: true;
$btn-box-shadow: none;

$dropdown-border-width: 0;
$dropdown-box-shadow: 6px 6px 18px 1px rgb(0 0 0 / 10%);
$dropdown-padding-y: 10px;
$dropdown-item-padding-x: 1rem;
$dropdown-item-padding-y: 8px;
$dropdown-link-hover-bg: #c4c4c433;
$dropdown-link-active-color: #3e4e57;
$dropdown-link-active-bg: $neutral-300;

$link-decoration: none;
$link-hover-decoration: underline;

$yiq-contrasted-threshold: 120;
$yiq-: $neutral-700;

$table-cell-padding: 1rem 0.75rem;
$table-head-color: $neutral-700;

$input-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-box-shadow: none;
$input-focus-box-shadow: none;

// $modal-content-border-color: $border-color;
$modal-content-box-shadow-sm-up: 0 10px 10px rgba(0, 0, 0, 0.25);
$modal-backdrop-opacity: 0.6;

$modal-lg: 720px;

$modal-xl: 960px;

$tooltip-arrow-width: 0;
$tooltip-arrow-height: 0;

$tooltip-border-radius: 10px;
$tooltip-opacity: 1;
$tooltip-padding-y: 10px;
$tooltip-padding-x: 1rem;
$tooltip-color: #ededed;

$popover-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
$popover-border-width: 0;
$popover-body-padding-y: 0;
$popover-body-padding-x: 0;

$dropdown-divider-bg: #c4c4c4;

$accordion-button-active-bg: transparent;
$accordion-button-focus-box-shadow: none;
$accordion-button-active-color: $dark;

$form-check-input-border: 2px solid $info;
$form-check-input-checked-bg-color: $info;
$form-check-input-border-radius: 0;

$modal-sm: 300px !default;
$modal-md: 425px;
$modal-lg: 800px !default;
$modal-xl: 1140px !default;

$modal-content-border-width: 0;
$modal-content-border-radius: 8px;

$font-weight-semi-bold: 600;
$font-weight-semibold: 600;
