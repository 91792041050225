.activate {
  background: $secondary-5
}
.org__logo {
  width: 60px;
  height: 60px;
  margin-top: 12px;
  margin-right: 1.3em;
  display: flex;
  align-items: center;
}
