.list__item-img {
  background-color: $secondary-5;
  border-radius: 50%;
  display: flex;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  //min-width: 68px;
  //max-width: 68px;
  //height: 68px;
  //min-height: 68px;
  @include media-breakpoint-down(sm) {
    border-radius: 6px;
  }
  img {
    display: flex;
    max-width: 65%;
    max-height: 65%;
  }
  &__tax img {
    max-width: 100% !important;
    max-height: 100% !important;
    height: 35px !important;
  }
}
