
          @import "@assets/scss/config/_index.scss";
.project__detail-img {
  height: auto;
  position: relative;
  max-height: 400px;
  max-width: 400px;
  min-height: 400px;
  order: 2;
  img {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
  }
  @include media-breakpoint-down(sm) {
    min-height: 320px;
    max-height: 320px;
    display: flex;
    align-items: center;
    justify-content: center
  }
  margin: 0;
  justify-content: center;
  flex: 0 0 auto;
}
.detailshero {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  top: 0;
  height: 400px;
  background: $secondary-3;
  padding: 3rem;
  border-radius: 3rem;
  z-index: -1
}