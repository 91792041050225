@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

h1,
h2:not(.project__detail-price),
h3:not(.szh-accordion__item-heading),
h4:not(.modal-title),
h5,
h6 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: $neutral-700;
  font-weight: 800;
  margin: 0;
}

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $neutral-700;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $body-bg;
  line-height: 1.378em;
  // --text-subtext: #6f6f90;
  // --text-color: #{$neutral-700};
  // --text-danger: #{$danger};
  // --border-input: #{$border-color};
  // --bkg-shade: #{$neutral-100};
  // --hero: linear-gradient(180deg, #5e5ff3, #5e5ff3);
}

* {
  -webkit-overflow-scrolling: touch;
}
a:not(.checkout__title) {
  // font-size: $font-size-base;
  // font-weight: 500;
  text-decoration: none;
  color: $neutral-700;
  cursor: pointer;
}

p {
  font-size: $font-size-base;
  line-height: 1.778em;
  margin: 0;
}

ul {
  margin: 0;
  font-size: $font-size-base;
}
input {
  font-size: $font-size-base !important;
  // padding: 21px;
  }

input::placeholder {
  color: $placeholder !important; /* Change to your desired color */
  opacity: 1; /* Ensures full opacity on all browsers */
}
label {
  font-size: $font-size-base;
}
iframe,
canvas {
  height: 0px;
}
.date {
  line-height: 1.03rem;
}
li:last-child:not(.ad__activity__item, .wishlist__item, .table__list-item, .cd__cart__item) {
  border: none !important;
}
.progress {
  background-color: $progress;
  height: 1.19rem !important;
}
.link {
  color: #2c4bff !important;
}
// nav-link for user & admin tab links
.nav-link {
  padding: 12px 16px;
  transition: none !important;
}

.clear {
  clear: both;
}
.price {
  color: $price;
  font-weight: $font-weight-bold;
  letter-spacing: -0.04em;
}
.select__input,
.select__control,
.select__menu {
  background-color: $secondary-5 !important;
  border-color: $border-color !important;
}
.select__option--is-focused {
  background: $secondary-1;
}
.select__option--is-selected {
  background: $secondary-2;
}
.select__value-container {
  padding: 12px;
}
.select__input-container {
  margin: 0;
  grid-area: 1 / 3;
  grid-template-columns: auto !important;
}
.select__placeholder {
  color: $neutral-700;
}
//border BS override.
.border,
.border-bottom,
.border-top,
.border-left,
.border-right,
.border-end,
.form-control {
  border-color: $border-color !important;
  transition: none !important;
}
.form-control,
.note,
.deactivate {
  background-color: $secondary-3;
  color: $neutral-700;
}
// CMS STYLES
.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1rem;
  overflow: auto;
}
.form-group {
  max-width: 100%;
  color: $neutral-700 !important;
}
.form-control:not(.donate-value-input, .search) {
  background-color: $body-bg;
  color: $neutral-700;
  // border-radius: 12px;
}
.form-control:disabled,
.form-control[readonly] {
  background: $secondary-4;
  opacity: 0.7;
}
.filter-text {
  color: $neutral-700;
}
.plan-content,
.plan-details,
.card,
.card-body,
.sw-card__content,
.sw-card__content > *,
.rdt_Table,
.rdt_Table > div,
.rdt_TableRow,
.rdt_TableHeader,
.rdt_TableHeadRow,
.rdt_Pagination,
.MuiTabs-root,
// .MuiModal-root span,
.MuiTabs-scroller {
  background: $secondary-6 !important;
  color: $neutral-700 !important;
  border-color: $border-color !important;
  outline-color: $border-color !important;
}
.rdt_Pagination path:first-child {
  fill: $neutral-700 !important;
}
.sw-settings-item,
.sw-settings-item__icon.background--enabled {
  background: $secondary-5 !important;
}
.rdt_TableRow {
  border-color: $border-color !important;
  outline-color: $border-color !important;
}
.rdt_TableRow:hover {
  background: $list !important;
}
.Mui-selected,
.MuiPaginationItem-root:hover,
.Mui-selected:focus {
  background-color: $secondary-1 !important;
}
.MuiButton-root {
  color: $neutral-700 !important;
}
.MuiPaper-root {
  border-color: $border-color !important;
  background: $secondary-6 !important;
  box-shadow: none !important;
  overflow: hidden;
  border-radius: 0;
  label {
    color: $neutral-700;
  }
  .Mui-selected {
    background: $secondary-5 !important;
  }
  border-color: $border-color !important;

  li {
    background: $secondary-6 !important;
    border-color: $border-color !important;
  }
  li:hover {
    background: $secondary-5 !important;
  }
}
.MuiPaginationItem-root {
  color: $neutral-600;
}
.react-mapbox-ac-menu {
  background: $secondary-5;
  border-color: $secondary-5;
}
