.category__item {
  position: relative;
  padding: 0px 12px;

  input {
    visibility: hidden;
    display: none;
  }

  .category__label {
    cursor: pointer;
    
    height: 42px;
    width: 100%;
    &.active {
      animation-name: bounce;
      animation-duration: 175ms;
      animation-iteration-count: 1;
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    
      .category__text {
        color: rgb(255, 255, 255);
      }
    }
  }

  .category__icon {
    height: 30px;
    width: 30px;
    margin: 8px;
  }

  .category__text {
    font-weight: $font-weight-semibold;
    color: var(--text-subtext);
  }
}
