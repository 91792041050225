.settings-tabs {


  .stab__btn {
    color: $neutral-700;
    cursor: pointer;
    font-weight: $font-weight-bold;
    line-height: 1;
    padding: 22px 22px 22px 8px;
    margin: 8px 0;
    justify-content: start;
    width: auto;

    &.active {
      color: $primary !important;
      .tab__active--indicator {
        display: block;
      }
      @include media-breakpoint-down(sm) {
        color: $primary !important;
    }
  }
    &:hover {
      text-decoration: none;
    }
  }

  .stab__icon {
    display: flex;
    justify-content: center;
    font-size: $font-size-base;
    width: 24px;
    margin-right: 16px;
  }

  .tab__active--indicator {
    display: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $primary;
    margin-left: auto;
  }
}

.stab__text {
  display: none;
}

@media screen and (min-width: 990px) {
  .stab__text {
    display: block;
  }

  .settings-tabs {
    width: 250px;
  }
}
@include media-breakpoint-down(md) {
  .settings-tabs {
    width: auto;

    .stab__icon {
      margin: 0;
      font-size: 22px;
    }

    .nav {
      display: flex;
      justify-content: space-between;
    }

    .stab__btn {
      padding-right: 8px;
      margin: 0;
      padding: 9px !important;

      &.active {
        color: $success;

        .tab__active--indicator {
          display: none;
        }
      }
    }
  }
}
