
          @import "@assets/scss/config/_index.scss";
.logo-name {
  display: flex;
  font-family: 'Fredoka', sans-serif;
  font-size: 29px;
  font-weight: 900;
  margin-bottom: 0 !important;
}
.logo-span {
  margin-left: 3px;
  color: $neutral-600;
  font-weight: 700;
  font-size: 12px;
}

.logo-icon {
  height: 23px;
}
