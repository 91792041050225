// .home {
//   background-color: $neutral-200;
// }
.infinite-scroll-component {
  overflow: hidden !important;
  grid-column-gap: 24px;
  grid-row-gap: 36px;
  flex: 1 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 479px) {
      grid-template-columns: 1fr;
    }

}
.bgimage {
  opacity: 0.19;
  height: 900px;
  position: absolute;
  width: 100%;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: -9;
  @include media-breakpoint-down(sm) {
    position: fixed !important;
  }
}

.section.section--hero {
  padding-top: 56px;
  padding-bottom: 24px;
  @include media-breakpoint-down(sm) {
    padding-top: 24px;
  }
}
.section {
  padding-top: 56px;
  padding-bottom: 112px;
  position: relative;
}

.container {
  max-width: 1305px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  position: relative;
}
.section--colored {
  border-top-left-radius: 120px;
  border-top-right-radius: 120px;
  padding-top: 76px;
  position: relative;
  background: $neutral-200;
  @include media-breakpoint-down(sm) {
    border-radius: 24px;
  }
}
.hero__left {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 44%;
  padding-right: 44px;
  display: flex;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
    padding-right: 0px;
  }
}
.hero__right {
  position: relative;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  img {
    width: 100%;
    max-width: 678px;
    margin-top: -35px;
    margin-left: 6px;
  }
}
.hero__heading {
  grid-row-gap: 19px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}
.hero__header {
  white-space: pre;
  flex-flow: column;
  margin-top: -28px;
  font-weight: 900;
  line-height: 0.99em;
  display: flex;
}
.hero__title {
  border-radius: 24px;
  width: 90%;
  height: 90px;
  margin-top: 28px;
  padding-top: 0;
  @include media-breakpoint-down(sm) {
    height: auto;
  }
}
.header__alt {
  color: $primary;
}
.item {
  grid-column-gap: 62px;
  grid-row-gap: 62px;
  flex-flow: column;
  display: flex;
  position: relative;
  img:not(.feature__img) {
    max-width: 85%;
    display: inline-block;
  }
}
.item__img {
  max-width: 85%;
  max-height: 275px;
}
.item__words {
  opacity: 0.45;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}
.text-block {
  opacity: 0.11;
  width: 150%;
  height: 150%;
  font-size: 46px;
  font-weight: 700;
  line-height: 1.3em;
  position: absolute;
  top: -15%;
  bottom: 0%;
  left: -9%;
  right: 0%;
  text-transform: uppercase;
  word-break: break-all;
}

.item__imgwrap {
  z-index: 99;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: -36px;
  padding-bottom: 0;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.item__wrap {
  border-radius: 16px;
  padding-bottom: 75%;
  position: relative;
  overflow: visible;
  // background-color: rgba(90, 174, 224, .2);
}
.item__bg:not(.feature) {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.29;
  border-radius: 16px;
}
.item__bg.main {
  opacity: 0.2;
  z-index: -1;
}
.search__container span {
  background: unset;
  border: unset;
}
