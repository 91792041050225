
          @import "@assets/scss/config/_index.scss";
.order__itemtotal {
  // background: $secondary-5;
  border-radius: 4px;
}
.email {
  &__container {
    padding: 6% 0;
  }
  &__list {
    gap: 1.3rem;
  }
  &__item {
    padding-bottom: 2rem;
  }
  &__item:last-child {
    padding-bottom: 0 !important;
    border: unset !important;
  }
}
.item__img-badge {
  background-color: #0e75f9 !important;
}
.checkout {
  &__title {
    padding-right: 0.3rem;
    color: $neutral-700;
    flex: 0 0 auto;
    font-size: 19px;
    max-width: 100%;
    color: inherit;
  }
  &__left {
    width: 275px;
    min-width: 175px;
  }
  &__right {
    width: 175px;
    max-width: 175px;
  }
}
.email__container {
  border-radius: 9px;
}
@include media-breakpoint-down(sm) {
  .boat-container {
    width: 100% !important;
  }
  .email__container {
    padding: 0;
    width: 100%;
  }
}
.total__wrapper.note {
  margin: auto;
  width: 100%;
}
/*.checkout {
  &__subtotal {
    margin-top: 3px;
    flex: 0 auto;
    color: $neutral-600;
    font-size: 16px;
    margin-right: 30px;
  }
  &__title {
    padding-right: 0.3rem;
    flex: 0 0 auto;
    font-size: 19px;
    max-width: 100%;
    color: inherit;
  }
  &__qtytag {
    position: absolute;
    left: auto;
    top: 0;
    right: 0;
    bottom: auto;
    width: 21px;
    height: 21px;
    margin-top: -5px;
    margin-right: -5px;
    padding-top: 3px;
    border-radius: 50%;
    background-color: $secondary;
    color: $neutral-100;
    font-size: 11px;
  }
  &__info {
    min-width: 90px;
    flex: 1;
  }
  &__brand {
    max-width: 200px;
    padding-top: 3px;
    
  }
  &__img {
    background-color: $secondary-3;
    flex: 1;
    border-radius: 6px;
    box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 1%);
    height: 100% !important;
  }
  &__thumb {
    width: 76px;
    height: 76px;
    margin-right: 12px;
    flex: 0 0 auto;
    border-radius: 6px;
  }
  &__left {
    flex: 0 auto;
  }
  &__top {
    flex: 0 0 auto;
  }
  &__price {
    display: none;
    padding-top: 6px;
  }
}
.avatar__checkout {
  object-fit: contain;
  background-color: $secondary-3;
  width: 80px;
}
.email {
  &__container {
    padding: 6% 0;
  }
  &__item {
    padding-top: 24px;
    padding-bottom: 38px;
    border-bottom: 1px solid #f5f5f5;
  }
  &__list {
    margin-top: 26px;
    padding-top: 3rem;
    border-top: 1px solid whitesmoke;
    gap: 0.9rem;
  }
}
.order {
  &__container {
    margin: 3% 15% 2% 15%;
    padding-top: 3%;
    min-height: 90px;
    border-top: 1px solid #f5f5f5;
  }
  &__wrap {
    text-align: left;
    display: inline-block;
    width: 70%;
    float: left;
  }
  &__label {
    font-size: 19px;
    color: $neutral-600;
  }
  &__value {
    text-align: right;
    display: inline-block;
    width: 25%;
    float: right;
  }
  &__xp {
    text-align: right;
    display: inline-block;
    width: 25%;
    float: right;
  }
}
.total {
  &__wrapper {
    margin: 2% 15% 0% 15%;
    min-height: 90px;
  }
  &__sub {
    text-align: left;
    display: inline-block;
    width: 70%;
    float: left;
  }
  &__value {
    text-align: right;
    display: inline-block;
    width: 25%;
    float: right;
  }
  &__title {
    font-size: 19px;
    color: $neutral-600;
  }
  &__total {
    text-align: right;
    display: inline-block;
    width: 25%;
    float: right;
  }
}
.checkout__page .item__img-badge {
  background-color: $secondary;
}
*/
.boat-container {
  position: relative;
  top: 0;
  height: 130px;
  width: 300px;
  overflow: hidden;
  .w-1 {
    opacity: 0.2;
    background-image: radial-gradient(circle at 50% 50%, transparent 60%, #0084e3 0),
      radial-gradient(circle at 50% 50%, #0084e3 40%, transparent 0),
      linear-gradient(#0084e3, #0084e3),
      radial-gradient(circle at 50% 50%, transparent 60%, #7dc9ff 0),
      radial-gradient(circle at 50% 50%, #7dc9ff 40%, transparent 0);
    background-size: 40px 80px, 80px 80px, 100% 60px, 40px 80px, 80px 80px;
    background-repeat: repeat-x;
    background-position: 0 -30px, -20px -1px, 0 40px, 0 -40px, -20px -11px;
    min-width: 1920px;
    height: 50px;
    position: absolute;
    bottom: 0;
    animation: w-1 38s linear infinite;
  }

  .w-1.r {
    transform: translateX(100%);
    animation: w-2 38s linear infinite;
  }

  * {
    margin: 0;
    padding: 0;
  }

  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  ul.no-bullet {
    list-style: none;
    padding: 0;
  }

  .inline-list {
    list-style: none;
    overflow: hidden;
  }

  .inline-list > li {
    list-style: none;
    float: left;
    display: block;
  }

  .inline-list > li > * {
    display: block;
  }

  .absolute {
    position: absolute;
  }

  .relative {
    position: relative;
  }

  .boat {
    bottom: -6px;
    left: 35px;
    z-index: 1;
    -webkit-animation: boatanimate 30s linear infinite;
    -moz-animation: boatanimate 30s linear infinite;
    -o-animation: boatanimate 30s linear infinite;
    animation: boatanimate 30s linear infinite;
  }

  .boat-body {
    width: 100px;
    height: 20px;
    background: #3898ec;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 20px;
  }

  .white-body {
    width: 65px;
    height: 16px;
    background: #0e75f9 !important;
    margin: 0px 13px;
    border-top-right-radius: 20px;
  }

  .windows {
    margin: 0px 12px !important;
    height: 100%;
    display: flex;
    justify-content: right;
  }

  .boat .circle {
    background: #fff;
    min-width: 7px;
    height: 7px;
    margin: 5px 3px;
    border-radius: 50%;
    z-index: 999;
    position: relative;
  }

  .smokestack {
    width: 10px;
    height: 17px;
    background: black;
    bottom: 30px;
    margin-left: 35px;
  }

  .fume {
    margin: 0px;
  }

  .fume li {
    background-color: #dbe5f4;
    border-radius: 50%;
  }

  .fume1 {
    width: 7px;
    height: 7px;
    margin-left: 38px;
    -webkit-animation: smokeup 2s linear infinite;
    -moz-animation: smokeup 2s linear infinite;
    -o-animation: smokeup 2s linear infinite;
    animation: smokeup 2s linear infinite;
  }

  .fume2 {
    width: 9px;
    height: 9px;
    margin-left: 28px;
    -webkit-animation: smokeup 2.1s linear infinite;
    -moz-animation: smokeup 2.1s linear infinite;
    -o-animation: smokeup 2.1s linear infinite;
    animation: smokeup 2.1s linear infinite;
  }

  .fume3 {
    width: 12px;
    height: 12px;
    margin-left: 38px;
    -webkit-animation: smokeup 2.2s linear infinite;
    -moz-animation: smokeup 2.2s linear infinite;
    -o-animation: smokeup 2.2s linear infinite;
    animation: smokeup 2.2s linear infinite;
  }

  .fume4 {
    width: 15px;
    height: 15px;
    margin-left: 28px;
    -webkit-animation: smokeup 2.3s linear infinite;
    -moz-animation: smokeup 2.3s linear infinite;
    -o-animation: smokeup 2.3s linear infinite;
    animation: smokeup 2.3s linear infinite;
  }

  @-webkit-keyframes smokeup {
    0% {
      margin-bottom: -3px;
      opacity: 1;
    }

    80% {
      margin-bottom: 7px;
      opacity: 0;
    }

    100% {
      margin-bottom: 7px;
      opacity: 0;
    }
  }

  @-moz-keyframes smokeup {
    0% {
      margin-bottom: -3px;
      opacity: 1;
    }

    80% {
      margin-bottom: 7px;
      opacity: 0;
    }

    100% {
      margin-bottom: 7px;
      opacity: 0;
    }
  }

  @-o-keyframes smokeup {
    0% {
      margin-bottom: -3px;
      opacity: 1;
    }

    80% {
      margin-bottom: 7px;
      opacity: 0;
    }

    100% {
      margin-bottom: 7px;
      opacity: 0;
    }
  }

  @keyframes smokeup {
    0% {
      margin-bottom: -3px;
      opacity: 1;
    }

    80% {
      margin-bottom: 7px;
      opacity: 0;
    }

    100% {
      margin-bottom: 7px;
      opacity: 0;
    }
  }

  @keyframes rock-boat {
    10% {
      transform: rotate(-1deg) translateY(-2px);
    }

    20% {
      transform: translateY(2px);
    }

    30% {
      transform: translateY(-2px);
    }

    40% {
      transform: translateY(2px);
    }

    50% {
      transform: rotate(-1deg) translateY(-2px);
    }

    100% {
      transform: rotate(1deg) translateY(2px);
    }
  }

  .boat {
    animation: rock-boat 2500ms ease-in-out infinite;
  }

  @keyframes w-1 {
    0% {
      transform: translateX(0);
    }

    49.99999% {
      transform: translateX(-100%);
    }

    50% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes w-2 {
    0% {
      transform: translateX(100%);
    }

    99.99999% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }
}
