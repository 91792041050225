/* Custom select */
.custom-select-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.custom-select {
  display: block;
  width: 100%;
  position: relative;
}

.custom-select [data-select] {
  display: block;
  border: 1px solid $border-color;
  border-radius: 4px;
  background-color: $neutral-100;
  padding: 15px 30px 15px 15px;
  position: relative;
  text-decoration: none;
  transition: color 0.25s ease-in-out;
}

.custom-select [data-select]:after {
  content: "";
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  right: 13px;
  color: #9796b1;
  position: absolute;
  top: 10px;
  transform: rotate(0deg);
}

.custom-select [data-select].custom-select-active,
.custom-select [data-select].selected,
.custom-select [data-select]:hover {
  color: #9796b1;
  font-size: 12px;
  font-weight: 600;
}

.custom-select [data-select].custom-select-active:after {
  transform: rotate(180deg);
}

.custom-select ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 3;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid $border-color;
  border-bottom: 0;
  background-color: $neutral-100;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: -2px;
  border-radius: 0 0 4px 4px;
  max-height: 240px;
  overflow-y: auto;
}

.custom-select ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
}

.custom-select ul li:last-child {
  border-bottom: none;
}

// .custom-select ul li.custom-select-active {
//   display: none;
// }

.custom-select ul a {
  text-decoration: none;
  display: block;
  color: #3d4279;
  
  font-weight: 600;
  padding: 20px;
  transition: all 0.25s ease-in-out;
  //text-align: center;
}

.custom-select ul .custom-select-active a,
.custom-select ul a:hover {
  background-color: $secondary-3;
  color: #3d4279;
}

.ns--choose ul .custom-select-active {
  display: none;
}

// Category item select

.item-category-select {
  .custom-select-wrapper {
    @media (max-width: 375px) {
      display: block;
    }
  }

  .title {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    color: $neutral-700;
    margin-bottom: 0.5rem;
  }

  .custom-select {
    margin-bottom: 20px;

    ul {
      top: -50px;
      max-width: 152px;
      border: none;

      @media (max-width: 375px) {
        max-width: 160px;
      }
    }
  }

  .custom-text-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    span {
      flex: 1;
    }
  }

  .custom-select [data-select] {
    background-color: $secondary-3;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 15px;
    max-height: 48px;

    &:after {
      display: none;
    }
  }
}
