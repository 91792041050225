.rounded-pill--xp {
  padding: 6px 12px 6px 12px !important;
  @include media-breakpoint-down(sm) {
    padding: 4px 9px 4px 9px !important;
  }
}
.page__bar {
  max-width: 375px;
  min-height: 46px;
}
.page__progress {
  max-width: 180px;
}
.project__detail-label {
  font-weight: 900;
}
.product__badge {
  .list__item-img {
    height: 56px !important;
    min-width: 56px !important;
    max-width: 56px !important;
    width: 56px !important;
    min-height: 56px !important;
    border: none !important;
  }
}
.project__detail-main {
  // color: $neutral-700;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}
.project__detail-title {
  font-size: 52px;
  color: $neutral-700;
  line-height: 3rem;
  // margin: 0.9rem 0;
  font-weight: 900 !important;
}
.project__count {
  width: 35px;
  min-width: 35px;
}
.project__detail-subtitle {
  font-size: 16px;
  font-weight: $font-weight-semi-bold;
  color: $neutral-600;
  margin-bottom: 0.9rem;
  // line-height: 2.3rem;
}
.project__detail-price {
  // font-family: 'linotte';
  font-weight: $font-weight-extra-bold;
}

.project__detail-meta {
  font-weight: $font-weight-semibold;
}
.project-video-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project-video-wrap {
  background-color: $secondary-3;
  border-radius: 6px;
  padding-bottom: 56%;
  position: relative;
  overflow: hidden;
}
.tag {
  width: 26px;
  height: 26px;
}

.tag__rounded {
  border-radius: 50% !important;
}

.category__icons {
  gap: 12px;
}
.page__blurb:first-letter {
  text-transform: capitalize;
}
.page__blurb {
  font-weight: 900;
  text-transform: lowercase;
}
.page__paragraph {
  margin: 0;
}

.sub__total {
  background-color: $secondary-3;
  padding: 20px 28px;
  border-radius: 6px;
  line-height: 30px;
  color: $neutral-700;
  display: flex;
  margin-bottom: 0.9rem;
}

.project__calculate {
  width: 100%;
  max-width: 350px;
}

@media (max-width: 544px) {
  .project__calculate,
  .btn--addtocart {
    width: 100%;
    max-width: 100% !important;
  }
}
@include media-breakpoint-down(sm) {
  .category__icons {
    order: -1;
  }
}
.btn__sold {
  padding: 2px 6px !important;
  border: 0 !important;
  margin-top: 0px !important;
  line-height: initial;
  height: auto;
  background: #37cc5e !important;
  color: #fff !important;
  &:hover {
    color: inherit;
    background: inherit;
  }
  .sold__icon {
    margin-right: 3px;
    height: 12px;
  }
  span {
    height: 15px;
  }
}
