
          @import "@assets/scss/config/_index.scss";
.gallery__img {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: -webkit-transform .15s ease 0s;
  transition: transform .15s ease 0s;
  transition: transform .15s ease 0s,-webkit-transform .15s ease 0s;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  height: 100%;
  width: 100%;
  border-radius: 9px;
  padding-bottom: 100%;
}

// .gallery__thumb {
//   padding-bottom: 100%;
// }

.gallery__link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery__thumb a {
  max-height: 100px;
  display: block;
  margin: auto;
  overflow: hidden;
  min-height: 100%;
  width: 100%;
  opacity: 0.9;
  position: absolute;
  float: left;
  top: 0;
  border-radius: 8px;
  left: 0;
  right: 0;
  bottom: 0;
}

.gallery__thumb a > .gallery__img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: transform 0.15s ease 0s;
  transform: scale3d(1, 1, 1);
  height: 100%;
  width: 100%;
}

.gallery__thumb a .gallery__hover {
  bottom: 0;
  left: 0;
  top: 0;
  position: absolute;
  right: 0;
  border-radius: 9px;
  transition: background-color 0.15s ease 0s;
}

.gallery__thumb a .gallery__hover > .zoom__icon {
  color: $neutral-100;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transition: opacity 0.3s ease 0s;
}

.gallery__hover .zoom__icon {
  font-size: 24px;
}

.gallery__thumb a:hover > .gallery__img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.gallery__thumb a:hover .gallery__hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.gallery__thumb a:hover .gallery__hover > .zoom__icon {
  opacity: 1;
}
