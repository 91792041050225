
          @import "@assets/scss/config/_index.scss";
.xp__btn-wrap {
  min-width: 230px;
}

.rank__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  .rank__item:last-child {
    @include media-breakpoint-down(sm) {
      border-bottom: none !important;
    }
  }
}
.rounded--pill {
  padding: 4px 12px 4px 9px !important;
  font-size: 11px;
}