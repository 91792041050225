
          @import "@assets/scss/config/_index.scss";
.fundraise__price {
  width: auto;
  max-width: 225px;
  color: $primary !important;
  input {
    color: $neutral-700;
    font-size: 46px !important;
  }
}
.fundraise__input {
  color: $neutral-700;
  font-size: 46px !important;
}
