.log__recent {
  display: flex;
  height: 90px;
  margin-bottom: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  align-items: center;
  border-bottom: 1px solid $border-color;
}

.log__avatar {
  position: relative;
  display: flex;
  width: 75px;
  height: 75px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}

.log__avatar__img {
  color: #6f6f90ad;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $secondary-3;
  background-image: none;
  font-size: 19px;
  display: flex;
  width: 54px;
  height: 54px;
  min-height: 54px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.log__title {
  font-weight: $font-weight-bold;
}

.billing__type {
  display: flex;
  height: 19px;
  margin-right: 21px;
  align-items: center;
  flex: 0 0 auto;
  color: #6f6f90ad;
  font-size: 11px;
  font-weight: 600;
}

.log__wrap {
    max-width: 400px;
}
@media (max-width: 544px) {
  .log__wrap {
    max-width: 100% !important;
  }
}

