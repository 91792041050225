.breadcrumb-slash {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  padding-top: 3px;
}

.breadcrumb-link {
  padding: 5px 10px;
  transition: background-color 0.1s, color 0.1s;
  text-decoration: none;
}
