
          @import "@assets/scss/config/_index.scss";
.feed__tag {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 12px;
  padding: 3px 12px;
  align-items: center;
  flex: 0 0 auto;
  cursor: pointer;

  .icon--feedtag.on,
  .icon--feedremove.on {
    align-items: center;
    display: flex;
    opacity: 1;
  }

  .icon--feedtag.on {
    transform: scale(1);
    margin-right: 8px;
  }

  .icon--feedremove.on {
    margin-left: 8px;
  }

  .icon--feedtag.off,
  .icon--feedremove.off {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .icon--feedtag.off {
    transform: scale(0);
  }

  .icon--feedremove,
  .icon--feedtag {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease;
  }

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  .icon__wrap {
    height: 32px;
    margin-right: 12px;
    width: 32px;
    display: flex;
    align-items: center;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
