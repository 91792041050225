
          @import "@assets/scss/config/_index.scss";
.icon__toggle-input {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
  &:checked + .icon__toggle-icon svg {
    fill: rgb(246, 100, 97);
    stroke: rgb(246, 100, 97);
    animation: bounce 250ms none;
    animation-timing-function: ease-in-out;
  }

  &:not(:checked) + .icon__toggle-icon svg {
    fill: transparent;
  }
}

.icon__toggle-label {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 36px;
  justify-content: center;
  position: relative;
  width: 36px;
  .icon__toggle-icon {
    font-size: 29px;
    svg {
      width: 1em;
    }
  }
}
