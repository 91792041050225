.footer {
  &.home {
    background-color: $neutral-200;
  }
  font-size: 16px;
  padding-top: 94px;
  padding-bottom: 112px;
  line-height: 1.5;

  &__block p {
    max-width: 300px;
  }
  &__wrap {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-rows: auto;
    grid-template-columns: 2fr 1fr;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }

    @include media-breakpoint-up(lg) {
      .loop__text {
        max-width: 428px;
      }
    }
  }
  &__middle {
    display: grid;
    grid-column-gap: 46px;
    grid-template-columns: 1fr 1fr 1fr;
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 21px;
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
      grid-row-gap: 36px;
    }
  }

  .logo-span {
    color: $neutral-700;
  }
  .svg__logo {
    display: flex;
    width: 21px;
    height: 21px;
    justify-content: center;
    align-items: center;
  }

  .svg__name {
    display: flex;
    margin-top: 0;
    font-family: 'Fredoka', sans-serif;
    font-size: 23px;
    font-weight: $font-weight-extra-bold;
  }
}

.footer__socialwrap {
  gap: 12px;
}

.footer__bottom {
  font-size: 14px;
  padding: 20px 0;
}

@include media-breakpoint-down(sm) {
  .col.footer__block {
    flex: 0 0 auto;
    width: 50%;
  }
}
