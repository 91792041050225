.btn__follow {
  font-size: 21px !important;
  width: 40px;
}

.btn__remove-follow {
  font-size: 16px;
  padding: 0;
  border-radius: 50%;
  height: 42px;
  width: 42px;
}
.ad__activity__item {
  background: $input;
}
.charity_avatar_cover > img {
  object-fit: cover;
  border-radius: 9px;
  height: 56px;
  width: 56px;
  max-width: 56px;
  max-height: 56px;
}
