
          @import "@assets/scss/config/_index.scss";
.terms * p {
  font-size: 16px;
}
.terms * b {
  font-size: 17px;
}
.terms ul * {
  padding: 12px;
}
.terms li {
  font-size: 16px;
}
