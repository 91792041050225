
          @import "@assets/scss/config/_index.scss";
.collection__list {
    display: flex;
  
    .icon__item {
      background: $secondary-5;
      border-radius: 8px;
      height: 175px;
      width: 175px;
      margin: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .icon__img-wrap {
      width: 60%;
      height: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
  
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  